import { useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import Discount from '@assets/svg/webapp/unsubscribe/discount_icon.svg';
import Fire from '@assets/svg/webapp/unsubscribe/fire.svg';
import Shield from '@assets/svg/webapp/unsubscribe/shield.svg';
import StepHeader from '../components/StepHeader';
import PlanUpdatedModal from '../components/PlanUpdatedModal';
import { firebaseAuth } from '@services/firebase';
import { useWebAppState } from '@components/WebApp/WebApp';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { createSubscriptionWithFirebaseId } from '@api/requests';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import {
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
} from '@constants/subscriptions';

function Step4({
  checkUserSubscriptions,
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  checkUserSubscriptions: () => void;
}) {
  const theme = useTheme();
  const [isUpdated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { closeUnsubscribe, userSubscription } = useWebAppState();
  const { subscriptions, cfTrackHeader } = usePaymentState();
  const { paymentConfig } = usePaymentConfig();

  const plan = {
    discount: 90,
    price: 13.99,
    full_price: 139.99,
  };

  const handleChangePlan = () => {
    const user = firebaseAuth.currentUser;

    if (loading || !user) return;

    const isAppleAuth = user.providerData[0]?.providerId === 'apple.com';
    const userUid = isAppleAuth ? user.providerData[0].uid : user.uid;
    const isSonara = userSubscription?.id.includes('-');
    const isCognifiProSubscription = userSubscription?.plan_code.includes('_pro_');
    const planId = isCognifiProSubscription
      ? PREMIUM_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID
      : PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID;

    const request = isSonara
      ? subscriptions.createSubscription({
          plan_id: planId,
          email: user.email ?? '',
          apple_token: isAppleAuth ? userUid : undefined,
          firebase_id: !isAppleAuth ? userUid : undefined,
        })
      : createSubscriptionWithFirebaseId({
          cfTrackHeader,
          url: paymentConfig.recurly.uri,
          plan_id: planId,
          email: user.email ?? '',
          apple_token: isAppleAuth ? userUid : undefined,
          firebase_id: !isAppleAuth ? userUid : undefined,
        });

    request
      .then(async (response) => {
        if ('error' in response) {
          setError(response.error);
        }
        if ('id' in response) {
          setUpdated(true);
          checkUserSubscriptions();
        }
      })
      .catch(async (error) => {
        const errorMessage = error?.error;
        setError(errorMessage ?? "Something went wrong, please contact support");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        background: '#F3F2F8',
      }}
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Change my plan"
      />
      <Box
        sx={{
          background: 'rgba(78, 176, 70, 0.10)',
          padding: '15px 20px',
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <Box>
          <Discount />
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '14px',
            span: {
              fontWeight: 600,
              color: '#4EB046',
            },
          }}
        >
          <span>Additional {plan.discount}% discount</span> has been applied just for you!
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          className="logo"
          width={'135px'}
          height={'132px'}
          src={'/cognifi/images/unsubscribe/step4_image.webp'}
          alt="logo"
        ></img>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '20px',
            span: {
              color: 'var(--primary-color)',
            },
          }}
        >
          Unlock premium brain-boosting tools while <span>saving {plan.discount}%!</span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginTop: '10px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Don’t miss your chance to supercharge your cognitive skills with all premium features at an unbeatable price.
        </Typography>
        <Box
          sx={{
            background: '#FFF',
            borderRadius: '16px',
            padding: '15px 20px',
            img: { width: '100%' },
          }}
        >
          <img src={'/cognifi/images/unsubscribe/chart.webp'} alt="chart"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              lineHeight: 'normal',
              fontSize: '12px',
              color: theme.palette.text.secondary,
              marginTop: '15px',
              textAlign: 'center',
              span: {
                fontFamily: 'Avenir600',
                color: 'var(--primary-color)',
              },
            }}
          >
            Based on our research, Cognify Premium users improve their cognitive skills <span>1.5x faster</span> by
            leveraging expert-backed tools.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '20px 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              One-time price offer:
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              ${plan.price}
            </Typography>
          </Box>
          <Box
            sx={{
              height: '1px',
              width: '100%',
              background: '#232631',
              opacity: 0.1,
              margin: '5px 0',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              Full price:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Typography
                sx={{
                  padding: '3px 5px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  fontFamily: 'Avenir600',
                  lineHeight: 'normal',
                  fontSize: '14px',
                  color: '#E53E3E',
                  background: 'rgba(246, 60, 71, 0.10)',
                  borderRadius: '20px',
                }}
              >
                <Fire />
                SAVE {plan.discount}%
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  lineHeight: 'normal',
                  fontSize: '14px',
                  color: '#E53E3E',
                  textDecoration: 'line-through',
                }}
              >
                ${plan.full_price}
              </Typography>
            </Box>
          </Box>
        </Box>
        {error !== '' && (
          <Box
            sx={{
              textAlign: 'center',
              width: '100%',
              paddingBottom: '15px',
              span: {
                color: '#E53E3E',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 'normal',
              },
            }}
          >
            <span>{error}</span>
          </Box>
        )}
        <QuizButton
          onClick={() => {
            handleChangePlan();
          }}
          variant={ButtonType.MAIN}
          text={''}
        >
          {loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            <>Change my plan</>
          )}
        </QuizButton>
      </Box>

      <Box
        sx={{
          padding: '20px',
          background: '#fff',
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',
            border: '1px solid #E6E7EB',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              padding: '10px 20px',
              background: '#F5F5F5',
              borderRadius: '20px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              fontFamily: 'Avenir600',
              lineHeight: 'normal',
              textAlign: 'center',
              fontSize: '14px',
              color: theme.palette.text.primary,
            }}
          >
            <Box>
              <Shield />
            </Box>
            100% money back guarantee
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              lineHeight: 'normal',
              fontSize: '12px',
              textAlign: 'center',
              color: '#A9A7AF',
            }}
          >
            Your Premium TrustCheck plan costs ${plan.price}/month. By tapping "Change my plan," you agree to a monthly
            subscription with automatic billing.
          </Typography>
        </Box>
      </Box>

      {isUpdated && (
        <PlanUpdatedModal
          onClick={() => {
            closeUnsubscribe();
          }}
        />
      )}
    </Box>
  );
}

export default Step4;
