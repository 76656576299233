import { PaymentConfigType } from '@providers/PaymentProvider/PaymentProvider.types';

const recurlyKeys =
  window.location.host.includes('dev.') || window.location.host.includes('localhost')
    ? {
        // DEV
        uri: 'https://subtrack-stg.appycnt.com/rec',
        pk: 'ewr1-0zDbCuPjqUmWy7RSE7KiBD',
      }
    : {
        // PROD
        uri: 'https://subvalid.devroqapps.com/rec',
        pk: 'ewr1-TZAIwcPilISuHDCPr2X84I',
      };

export const APP_CONFIG: PaymentConfigType = {
  recurly: recurlyKeys,
  plans: [
    {
      id: 'cognifi_weekly_trial3',
      sum: 19.99,
      trial_sum: 1,
      length: '7d',
      discount_percent: 30,
      name: 'IQ Weekly Plan',
      name_full: 'Weekly Plan',
    },
    {
      id: 'cognifi_3month_plan',
      sum: 48,
      trial_sum: 48,
      length: '84d',
      discount_percent: 85,
      name: 'IQ 3-Month Plan',
      name_full: '3-Month',
    },
    {
      id: 'cognifi_3month_plan_trial',
      sum: 49,
      trial_sum: 49,
      length: '3m',
      discount_percent: 85,
      name: 'IQ 3-Month Plan',
      name_full: '3-Month',
    },
    {
      id: 'cognifi_early_access_otp',
      sum: 19.99,
      trial_sum: 19.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ Early Access to New Games',
      name_full: 'Early Access to New Games',
    },
    {
      id: 'cognifi_early_access_sale_otp',
      sum: 14.99,
      trial_sum: 14.99,
      length: '120m',
      discount_percent: 55,
      name: 'IQ Early Access to New Games sale',
      name_full: 'Early Access to New Games',
    },
    {
      id: 'cognifi_extended_analysis_report_otp',
      sum: 19.99,
      trial_sum: 19.99,
      length: '120m',
      discount_percent: 33,
      name: 'IQ Extended Analysis Report',
      name_full: 'Extended Analysis Report',
    },
    {
      id: 'cognifi_extended_analysis_report_sale_otp',
      sum: 14.99,
      trial_sum: 14.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ Extended Analysis Report sale',
      name_full: 'Extended Analysis Report',
    },
    {
      id: 'cognifi_offline_access_sale_otp',
      sum: 4.99,
      trial_sum: 4.99,
      length: '120m',
      discount_percent: 50,
      name: 'IQ Offline Access sale',
      name_full: 'Offline Access',
    },
    {
      id: 'cognifi_pro_3month_plan',
      sum: 48,
      trial_sum: 48,
      length: '84d',
      discount_percent: 85,
      name: 'IQ pro 3-Month Plan',
      name_full: '3-Month',
    },
    {
      id: 'cognifi_pro_3month_plan_trial',
      sum: 49,
      trial_sum: 49,
      length: '3m',
      discount_percent: 85,
      name: 'IQ pro 3-Month Plan',
      name_full: '3-Month',
    },
    {
      id: 'cognifi_pro_early_access_otp',
      sum: 19.99,
      trial_sum: 19.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ pro Early Access to New Games',
      name_full: 'Early Access to New Games',
    },
    {
      id: 'cognifi_pro_early_access_sale_otp',
      sum: 14.99,
      trial_sum: 14.99,
      length: '120m',
      discount_percent: 55,
      name: 'IQ pro Early Access to New Games sale',
      name_full: 'Early Access to New Games',
    },
    {
      id: 'cognifi_pro_extended_analysis_report_otp',
      sum: 19.99,
      trial_sum: 19.99,
      length: '120m',
      discount_percent: 33,
      name: 'IQ pro Extended Analysis Report',
      name_full: 'Extended Analysis Report',
    },
    {
      id: 'cognifi_pro_extended_analysis_report_sale_otp',
      sum: 14.99,
      trial_sum: 14.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ pro Extended Analysis Report sale',
      name_full: 'Extended Analysis Report',
    },
    {
      id: 'cognifi_pro_extended_analysis_report_sale2_otp',
      sum: 11.99,
      trial_sum: 11.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ pro Extended Analysis Report sale2',
      name_full: 'Extended Analysis Report',
    },
    {
      id: 'cognifi_pro_offline_access_sale_otp',
      sum: 4.99,
      trial_sum: 4.99,
      length: '120m',
      discount_percent: 50,
      name: 'IQ pro Offline Access',
      name_full: 'Offline Access',
    },
    {
      id: 'cognifi_pro_ultimate_pack_otp',
      sum: 32.99,
      trial_sum: 32.99,
      length: '120m',
      discount_percent: 67,
      name: 'IQ pro Ultimate Pack',
      name_full: 'Ultimate Pack',
    },
    {
      id: 'cognifi_pro_ultimate_pack_sale_otp',
      sum: 28.99,
      trial_sum: 28.99,
      length: '120m',
      discount_percent: 75,
      name: 'IQ pro Ultimate Pack sale',
      name_full: 'Ultimate Pack',
    },
    {
      id: 'cognifi_pro_weekly_trial3',
      sum: 19.99,
      trial_sum: 1,
      length: '7d',
      discount_percent: 30,
      name: 'IQ pro Weekly Plan',
      name_full: 'Weekly Plan',
    },
    {
      id: 'cognifi_ultimate_pack_otp',
      sum: 32.99,
      trial_sum: 32.99,
      length: '120m',
      discount_percent: 67,
      name: 'IQ Ultimate Pack',
      name_full: 'Ultimate Pack',
    },
    {
      id: 'cognifi_ultimate_pack_sale_otp',
      sum: 28.99,
      trial_sum: 28.99,
      length: '120m',
      discount_percent: 75,
      name: 'IQ pro Ultimate Pack sale',
      name_full: 'Ultimate Pack',
    },
    {
      id: 'cognifi_extended_analysis_report_sale2_otp',
      sum: 11.99,
      trial_sum: 11.99,
      length: '120m',
      discount_percent: 45,
      name: 'IQ pro Extended Analysis Report sale2',
      name_full: 'Extended Analysis Report',
    },
  ],
};
