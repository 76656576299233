import { useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import * as animation from '@assets/json/JSON_Cognifi-Logo-Loop.json';
import Lottie from 'react-lottie-player';

function Step2({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setStep((prev) => prev + 1);
    }, 2500);
  }, [setStep]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/cognifi/images/gradient_background_fullscreen.webp)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie
          play
          animationData={animation}
          style={{
            width: '70px',
            height: '52px',
          }}
        />

        <Typography
          sx={{
            fontSize: '20px',
            color: theme.palette.text.primary,
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            marginTop: '20px',
          }}
        >
          Please wait
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            color: theme.palette.text.secondary,
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
          }}
        >
          This may take a few seconds
        </Typography>
      </Box>
    </Box>
  );
}

export default Step2;
