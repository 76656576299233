import { createContext, ReactNode, useMemo, useState } from 'react';
import { PaymentConfigType, PaymentPlanType } from '@providers/PaymentProvider/PaymentProvider.types';
import { getHost } from '@services/hostUtils';
import { APP_CONFIG } from '../../AppConfig';

export const PaymentConfigContext = createContext<null | {
  paymentConfig: PaymentConfigType;
  selectedPlan: PaymentPlanType;
  skipTrialPlan: null | PaymentPlanType;
  ultPackPlan: null | PaymentPlanType;
  ultPackSalePlan: null | PaymentPlanType;
  earlyAccessPlan: null | PaymentPlanType;
  earlyAccessSalePlan: null | PaymentPlanType;
  analysisReportPlan: null | PaymentPlanType;
  analysisReportSalePlan: null | PaymentPlanType;
  analysisReportSale2Plan: null | PaymentPlanType;
  offlineAccessSalePlan: null | PaymentPlanType;
  showAdditionalOffersPrice: boolean;
  showSkipPopup: boolean;
  isFacebookPurchaseEventMustBePostponed: boolean;
  isCognifiPro: boolean;
}>(null);

function PaymentConfigProvider({ children }: { children: ReactNode }) {
  const paymentConfig = APP_CONFIG;
  const isCognifiPro = getHost(2) === 'cognifi.pro';

  const [planError, setPlanError] = useState<string | null>(null);
  const findPlanByParameter = (param: string) => {
    const paramPlanId = new URLSearchParams(window.location.search).get(param);
    if (paramPlanId !== '' && paramPlanId !== null) {
      const plan = APP_CONFIG.plans.find((el) => el.id === paramPlanId);
      if (plan) {
        return plan;
      } else {
        setPlanError(`Can't find "${paramPlanId}" for "${param}" parameter`);
      }
    }
    return null;
  };

  const selectedPlan = useMemo(() => {
    const planId = new URLSearchParams(window.location.search).get('plan');
    const defaultMainPlan =
      findPlanByParameter(isCognifiPro ? 'cognifi_pro_weekly_trial3' : 'cognifi_weekly_trial3') ??
      paymentConfig.plans[0];
    if (planId !== '' && planId !== null) {
      return findPlanByParameter('plan') ?? defaultMainPlan;
    }
    return defaultMainPlan;
  }, [isCognifiPro, paymentConfig.plans]);

  const showAdditionalOffersPrice = new URLSearchParams(window.location.search).get('additional_offer_dc') === 'true';

  const showSkipPopup = new URLSearchParams(window.location.search).get('sure_popup') === 'true';

  const skip_trial_plan = new URLSearchParams(window.location.search).get('skip_trial_plan');
  const isFacebookPurchaseEventMustBePostponed = skip_trial_plan !== '' && skip_trial_plan !== null;

  const skipTrialPlan = useMemo(() => findPlanByParameter('skip_trial_plan'), []);

  const ultPackPlan = useMemo(() => findPlanByParameter('ult_pack'), []);
  const ultPackSalePlan = useMemo(() => findPlanByParameter('ult_pack_s'), []);

  const earlyAccessPlan = useMemo(() => findPlanByParameter('early_access'), []);
  const earlyAccessSalePlan = useMemo(() => findPlanByParameter('early_access_s'), []);

  const analysisReportPlan = useMemo(() => findPlanByParameter('analysis_report'), []);
  const analysisReportSalePlan = useMemo(() => findPlanByParameter('analysis_report_s'), []);
  const analysisReportSale2Plan = useMemo(() => findPlanByParameter('analysis_report_s2'), []);

  const offlineAccessSalePlan = useMemo(() => findPlanByParameter('offline_access_s'), []);

  const value = {
    paymentConfig,
    selectedPlan,
    showAdditionalOffersPrice,
    ultPackPlan,
    ultPackSalePlan,
    earlyAccessPlan,
    earlyAccessSalePlan,
    analysisReportPlan,
    analysisReportSalePlan,
    analysisReportSale2Plan,
    offlineAccessSalePlan,
    skipTrialPlan,
    showSkipPopup,
    isFacebookPurchaseEventMustBePostponed,
    isCognifiPro,
  };

  return (
    <PaymentConfigContext.Provider value={value}>
      {planError && (
        <div
          style={{
            position: 'fixed',
            zIndex: 9999999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '50px',
            textAlign: 'center',
          }}
        >
          {planError}
        </div>
      )}
      {children}
    </PaymentConfigContext.Provider>
  );
}

export default PaymentConfigProvider;
