import { trackEvent } from '@api/requests';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { useCallback, useMemo } from 'react';
import { Routes } from '../../../App';

function Offline({ setStepNumber }: { setStepNumber: React.Dispatch<React.SetStateAction<number>> }) {
  const {
    isAdditionalPaymentLoading,
    additionalPaymentError,
    handleAdditionalOfferAdd,
    cfTrackHeader,
  } = usePaymentState();
  const { offlineAccessSalePlan, showAdditionalOffersPrice } = usePaymentConfig();
  const { userLocation } = useQuizState();

  const handleSkip = () => {
    trackEvent(`Skip ${offlineAccessSalePlan?.name} click`, cfTrackHeader, userLocation).catch(console.error);
    setStepNumber((prev) => prev + 1);
  };

  const handleGetTheOffer = useCallback(() => {
    if (isAdditionalPaymentLoading) return;

    if (offlineAccessSalePlan) {
      handleAdditionalOfferAdd(offlineAccessSalePlan, () => {
        setStepNumber((prev) => prev + 1);
      });
    }
  }, [offlineAccessSalePlan, isAdditionalPaymentLoading]);

  const points = useMemo(() => {
    return [
      {
        title: 'Play games anytime, anywhere',
        image: 'svg/offer/p9.svg',
      },
      {
        title: 'Broad selection of brain games',
        image: 'svg/offer/p10.svg',
      },
      {
        title: 'Keep getting better, even offline',
        image: 'svg/offer/p11.svg',
      },
      ...(showAdditionalOffersPrice
        ? [
            {
              title: `One-time payment $${offlineAccessSalePlan?.sum}`,
              image: 'svg/offer/p4.svg',
            },
          ]
        : []),
    ];
  }, [offlineAccessSalePlan, showAdditionalOffersPrice]);

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <img width={128} src="images/subscription/offline_access.webp" alt="decor"></img>
      <Typography
        sx={{
          margin: '20px 0 10px',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
          span: {
            color: '#6639E6',
          },
        }}
      >
        Explore <span>offline access</span>
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '16px',
          lineHeight: 'normal',
          color: 'rgba(2, 2, 2, 0.50)',
          textAlign: 'center',
        }}
      >
        Instant access from your home screen - no internet required
      </Typography>
      <Box
        sx={{
          padding: '20px',
          borderRadius: ' 20px',
          background: '#F3F2F8',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          margin: '20px 0',
        }}
      >
        {points.map((el) => (
          <Box
            key={el.title}
            sx={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <img src={el.image} alt="point"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#020202',
              }}
            >
              {el.title}
            </Typography>
          </Box>
        ))}
      </Box>

      {additionalPaymentError && (
        <Typography
          sx={{
            margin: '10px 20px',
            color: '#E03045',
            textAlign: 'center',
          }}
        >
          {additionalPaymentError}
        </Typography>
      )}
      <QuizButton onClick={handleGetTheOffer} variant={ButtonType.MAIN} text="">
        {isAdditionalPaymentLoading ? (
          <CircularProgress size={20} style={{ color: 'white' }}></CircularProgress>
        ) : (
          <>Get offline access</>
        )}
      </QuizButton>
      <Typography
        onClick={handleSkip}
        sx={{
          cursor: 'pointer',
          fontFamily: 'Avenir400',
          fontSize: '14px',
          lineHeight: 'normal',
          color: '#A9A7AF',
          textAlign: 'center',
          marginTop: '15px',
          textDecoration: 'underline',
        }}
      >
        Skip this offer and proceed further
      </Typography>

      {!showAdditionalOffersPrice && (
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            color: '#A9A7AF',
            textAlign: 'center',
            marginTop: '15px',
            '& .link': {
              color: '#A9A7AF',
              textDecorationColor: '#A9A7AF',
            },
          }}
        >
          By clicking the button, I agree to enroll in a one-time subscription at the cost of $
          {offlineAccessSalePlan?.sum}. I have thoroughly reviewed and accepted the{' '}
          <Link target="_blank" href={Routes.TERMS} className="link">
            Terms and Conditions.
          </Link>
        </Typography>
      )}
    </Box>
  );
}

export default Offline;
