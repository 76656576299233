import React, { useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';
import RecurlyForm from './components/RecurlyForm';
import SonaraForm from './components/SonaraForm';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function PaymentModal() {
  const { setQuizData, quizData } = useQuizState();
  const { selectedPlan } = usePaymentConfig();
  const { isUseCardSonara } = usePaymentState();

  useEffect(() => {
    if (quizData.isPaymentModalOpen) {
      wmq.track(EvTruck.ModalPaymentShown);
    }
  }, [quizData.isPaymentModalOpen]);

  return (
    <Modal
      open={quizData.isPaymentModalOpen}
      onClose={() => {
        setQuizData((prev) => ({ ...prev, isPaymentModalOpen: false }));
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          overflow: 'auto',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
            marginTop: 'auto',
            maxWidth: '500px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '20px 20px 0 0',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              onClick={() => {
                setQuizData((prev) => ({
                  ...prev,
                  isPaymentModalOpen: false,
                }));
              }}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
              }}
              src={'svg/close.svg'}
              alt="close"
            ></img>
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Avenir600',
                fontSize: '20px',
                padding: '15px',
              }}
            >
              Pay with card
            </Typography>
            <Box
              sx={{
                padding: '0 20px 20px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  background: '#F3F2F8',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0 -20px',
                  padding: '15px 20px',
                }}
              >
                <Typography
                  sx={{
                    color: '#020202',
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    textAlign: 'left',
                  }}
                >
                  Total due today:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#020202',
                      fontFamily: 'Avenir600',
                      fontSize: '16px',
                      textAlign: 'right',
                    }}
                  >
                    <span
                      style={{
                        color: '#E53E3E',
                        fontFamily: 'Avenir600',
                        fontSize: '16px',
                        textDecoration: 'line-through',
                      }}
                    >
                      $9.99
                    </span>{' '}
                    ${selectedPlan.trial_sum ?? selectedPlan.sum}
                  </Typography>
                  <Box
                    sx={{
                      padding: '5px 10px',
                      borderRadius: '20px',
                      background: '#E53E3E',
                      width: 'max-content',
                      display: 'flex',
                    }}
                  >
                    <img src="svg/fire.svg" alt="fire"></img>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir600',
                        fontSize: '10px',
                        color: '#fff',
                        marginLeft: '6px',
                      }}
                    >
                      You just saved 90%
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {isUseCardSonara ? <SonaraForm /> : <RecurlyForm />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default PaymentModal;
