import React, { FormEvent, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { EMAIL_REGEX } from '@utils/constants';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { usePaymentInputs } from 'react-payment-inputs';
import { clearNumber } from '@utils/numbers';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function SonaraForm() {
  const { setQuizData, quizData } = useQuizState();
  const { isPaymentLoading, paymentFormError, handleSonaraSubmitForm } = usePaymentState();
  const [isValidFirstName, setValidFirstName] = useState(quizData.firstName.trim() !== '');
  const [isValidLastName, setValidLastName] = useState(quizData.lastName.trim() !== '');
  const [isValidEmail, setValidEmail] = useState(Boolean(quizData.email.toLowerCase().match(EMAIL_REGEX)));
  const [showErrors, setShowErros] = useState(false);
  const [cardNumber, setCardNumber] = React.useState('');
  const [expDate, setExpDate] = React.useState('');
  const [cvc, setCvc] = React.useState('');
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
  const [isDiscoverCard, setDiscoverCard] = useState(false);

  const handleSubmitClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isPaymentLoading) return;

    const isValid =
      !isDiscoverCard &&
      isValidEmail &&
      isValidFirstName &&
      isValidLastName &&
      !meta.erroredInputs.cardNumber &&
      !meta.erroredInputs.expiryDate &&
      !meta.erroredInputs.cvc;

    if (isValid) {
      const month = clearNumber(expDate.split('/')[0]);
      const year = clearNumber(expDate.split('/')[1]);
      handleSonaraSubmitForm({
        number: clearNumber(cardNumber),
        exp_month: Number(month),
        exp_year: Number('20' + year),
        cvv: cvc,
      });
    } else {
      setShowErros(true);
    }
  };

  const handleChangeCardNumber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCardNumber(
      e.target.value
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
    );
  };

  useEffect(() => {
    const discoverCards = [6011, 644, 645, 646, 647, 648, 649, 65];
    const userCardNumber = cardNumber.replaceAll(' ', '');

    if (userCardNumber.length === 16) {
      const isDiscover = discoverCards.some((cardNumber) => {
        return userCardNumber.startsWith(cardNumber.toString());
      });
      setDiscoverCard(isDiscover);
    } else {
      setDiscoverCard(false);
    }
  }, [cardNumber]);

  return (
    <form
      onSubmit={handleSubmitClick}
      onChange={() => {
        setShowErros(false);
      }}
    >
      <Box
        sx={{
          margin: '15px 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          '& .wrapper': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          },
          '& input': {
            width: 'calc(100%)',
            borderRadius: '8px',
            border: '1px solid #E6E7EB',
            paddingLeft: '15px',
            height: '48px',
            boxSizing: 'border-box',
            color: '#020202',
            fontFamily: 'Avenir500',
            fontSize: '16px',
            outline: 'none',
            '&:hover': {
              outline: 'none',
            },
          },
          '& .input-title': {
            color: '#020202',
            fontFamily: 'Avenir500',
            fontSize: '14px',
            textAlign: 'left',
            marginBottom: '5px',
          },
          '& .error': {
            width: '100%',
            color: '#E03045',
            fontFamily: 'Avenir400',
            fontSize: '10px',
            textAlign: 'left',
            marginTop: '5px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Box className="wrapper">
            <Typography className="input-title">First name</Typography>
            <input
              type="text"
              value={quizData.firstName}
              onChange={(event) => {
                setQuizData((prev) => ({
                  ...prev,
                  firstName: event.target.value,
                }));
                setValidFirstName(event.target.value.trim() !== '');
              }}
            ></input>
            {showErrors && !isValidFirstName && <Typography className="error">Fill in the field</Typography>}
          </Box>
          <Box className="wrapper">
            <Typography className="input-title">Last name</Typography>
            <input
              type="text"
              value={quizData.lastName}
              onChange={(event) => {
                setQuizData((prev) => ({
                  ...prev,
                  lastName: event.target.value,
                }));
                setValidLastName(event.target.value.trim() !== '');
              }}
            ></input>
            {showErrors && !isValidLastName && <Typography className="error">Fill in the field</Typography>}
          </Box>
        </Box>
        <Box className="wrapper">
          <Typography className="input-title">Email</Typography>
          <input
            type="email"
            value={quizData.email}
            onChange={(event) => {
              const isValid = event.target.value.toLowerCase().match(EMAIL_REGEX);
              setValidEmail(Boolean(isValid));
              setQuizData((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            }}
          ></input>
          {showErrors && !isValidEmail && <Typography className="error">Please enter a valid email</Typography>}
        </Box>
        <Typography className="input-title">Card number</Typography>
        <Box className="input-wrapper">
          <input
            {...getCardNumberProps({
              onChange: handleChangeCardNumber,
            })}
            placeholder="XXXX XXXX XXXX XXXX"
            value={cardNumber}
          />
        </Box>
        {showErrors && meta.erroredInputs.cardNumber && (
          <Typography className="error">{meta.erroredInputs.cardNumber}</Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginTop: '15px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography className="input-title">Expires on</Typography>
            <Box>
              <Box className="input-wrapper">
                <input
                  {...getExpiryDateProps({
                    onChange: (e: any) => {
                      setExpDate(e.target.value);
                    },
                  })}
                  placeholder="MM/YY"
                />
              </Box>
            </Box>
            {showErrors && meta.erroredInputs.expiryDate && (
              <Typography className="error" style={{}}>
                {meta.erroredInputs.expiryDate}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography className="input-title">CVC</Typography>
            <Box className="input-wrapper">
              <input
                {...getCVCProps({
                  onChange: (e: any) => {
                    setCvc(e.target.value);
                  },
                })}
                placeholder="•••"
              />
            </Box>
            {showErrors && meta.erroredInputs.cvc && (
              <Typography className="error">{meta.erroredInputs.cvc}</Typography>
            )}
          </Box>
        </Box>
      </Box>

      {paymentFormError && (
        <Typography
          sx={{
            marginBottom: '20px',
            color: '#E03045',
            fontFamily: 'Avenir500',
            fontSize: '14px',
          }}
        >
          {paymentFormError}
        </Typography>
      )}
      {isDiscoverCard && (
        <Typography
          sx={{
            marginBottom: '20px',
            color: '#E03045',
            fontFamily: 'Avenir500',
            fontSize: '14px',
          }}
        >
          Discover cards are not accepted.
        </Typography>
      )}

      <QuizButton
        type="submit"
        onClick={() => {}}
        variant={ButtonType.MAIN}
        text={isPaymentLoading ? '' : 'Proceed payment'}
      >
        {isPaymentLoading ? <CircularProgress style={{ color: 'white' }}></CircularProgress> : undefined}
      </QuizButton>
    </form>
  );
}

export default SonaraForm;
