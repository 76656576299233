import { Box, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import { useWebAppState } from '@components/WebApp/WebApp';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import DividerDecor from '@assets/svg/webapp/unsubscribe/divider_decor.svg';
import PlayIcon from '@assets/svg/webapp/unsubscribe/play_icon.svg';
import BrainIcon from '@assets/svg/webapp/unsubscribe/brain_icon.svg';
import HacksIcon from '@assets/svg/webapp/unsubscribe/hacks_icon.svg';
import TipsIcon from '@assets/svg/webapp/unsubscribe/tips_icon.svg';
import Checkmark from '@assets/svg/webapp/unsubscribe/checkmark.svg';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../App';

const Divider = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
      }}
    >
      <Box
        sx={{
          height: '1px',
          width: '100%',
          background: '#E6E7EB',
        }}
      />
      <Box>
        <DividerDecor />
      </Box>
      <Box
        sx={{
          height: '1px',
          width: '100%',
          background: '#E6E7EB',
        }}
      />
    </Box>
  );
};

function Step5({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const { closeUnsubscribe } = useWebAppState();
  const navigate = useNavigate();
  const theme = useTheme();

  const points = [
    <>
      Start your day with a <span>quick puzzle game</span>
    </>,
    <>
      Engage in 20-30 minutes of <span>exercise</span>
    </>,
    <>
      Eat foods rich in <span>omega-3s</span>, <span>antioxidants</span>, and <span>healthy fats</span>
    </>,
    <>
      Set aside 90-minute <span>focus blocks</span> with no distractions
    </>,
    <>
      Before bed, <span>journal or review</span> what you learned today
    </>,
  ];

  const handleGoTo = (path: string) => {
    closeUnsubscribe();
    navigate(path);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        backgroundImage: 'url(/cognifi/images/gradient_background.webp)',
        backgroundSize: '100% 400px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 2);
        }}
        title="Your Membership"
      />
      <Box
        sx={{
          padding: '30px 20px 20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            span: {
              color: 'var(--primary-color)',
            },
          }}
        >
          Have You Unlocked the <span>Full Power</span> of Cognify?
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginTop: '10px',
            textAlign: 'center',
          }}
        >
          Make sure you’ve taken advantage of everything your subscription offers before saying goodbye!
        </Typography>
      </Box>
      <Box
        sx={{
          img: { width: '100%', display: 'flex' },
        }}
      >
        <img src={'/cognifi/images/unsubscribe/membership_background.webp'} alt="membership"></img>
      </Box>
      <Box
        sx={{
          background: '#F3F2F8',
          padding: '20px 20px 40px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
          }}
        >
          Did You Know?
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            color: '#8C8E96',
            marginTop: '10px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Your IQ isn’t fixed - your brain can literally rewire itself to become smarter!
        </Typography>
        <QuizButton
          onClick={() => {
            handleGoTo(Routes.WEBAPP_TESTS);
          }}
          variant={ButtonType.MAIN}
          text={'Take IQ Test'}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '12px',
            color: '#8C8E96',
            marginTop: '10px',
            textAlign: 'center',
          }}
        >
          Take your IQ test regularly and watch your growth
        </Typography>
        <Divider />

        <Box
          sx={{
            background: '#fff',
            borderRadius: '20px',
            boxShadow: '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              padding: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                lineHeight: 'normal',
                fontSize: '20px',
                color: '#020202',
                marginBottom: '10px',
              }}
            >
              "Play is the highest form of research"
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'max-content',
                gap: '6px',
                paddingRight: '20px',
                borderRadius: '8px',
                overflow: 'hidden',
                background: '#F3F2F8',
                fontFamily: 'Avenir600',
                lineHeight: 'normal',
                fontSize: '12px',
                color: '#020202',
                img: {
                  width: '40px',
                },
              }}
            >
              <img src={'/cognifi/images/unsubscribe/albert.webp'} alt="albert"></img>
              Albert Einstein
            </Box>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '16px',
                color: 'rgba(2, 2, 2, 0.50)',
                marginTop: '10px',
                marginBottom: '20px',
              }}
            >
              Modern neuroscience confirms that games can strengthen cognitive functions like memory, reasoning, and
              decision-making—so keep playing and keep growing!
            </Typography>
            <QuizButton
              onClick={() => {
                handleGoTo(Routes.WEBAPP_GAMES);
              }}
              variant={ButtonType.MAIN}
              text={''}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <PlayIcon />
                Let’s Play
              </Box>
            </QuizButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              img: {
                width: '100%',
              },
            }}
          >
            <img src={'/cognifi/images/unsubscribe/play_banner.webp'} alt="play"></img>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            background: '#fff',
            borderRadius: '20px',
            boxShadow: '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              lineHeight: 'normal',
              fontSize: '20px',
              color: '#020202',
              marginBottom: '10px',
            }}
          >
            Intelligence Hack:
          </Typography>
          <Box
            sx={{
              padding: '20px',
              borderRadius: '8px',
              background: '#F3F2F8',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                lineHeight: 'normal',
                fontSize: '16px',
                color: '#020202',
                marginBottom: '6px',
              }}
            >
              The "Why x5" Method
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '16px',
                color: 'rgba(2, 2, 2, 0.50)',
              }}
            >
              When faced with a problem, ask "Why?" five times to uncover the deeper root cause. Each answer leads to a
              new "Why," forcing deeper thinking and sharpening problem-solving skills.
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir500',
              lineHeight: 'normal',
              fontSize: '16px',
              color: '#020202',
              marginTop: '10px',
              marginBottom: '20px',
              span: {
                color: 'var(--primary-color)',
              },
            }}
          >
            This simple trick rewires your brain to <span>think critically</span> and{' '}
            <span>uncover smarter solutions!</span>
          </Typography>
          <QuizButton
            onClick={() => {
              handleGoTo(Routes.WEBAPP_ARTICLES);
            }}
            variant={ButtonType.MAIN}
            text={''}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <HacksIcon />
              Get more hacks
            </Box>
          </QuizButton>
        </Box>
        <Divider />
        <Box
          sx={{
            background: '#fff',
            borderRadius: '20px',
            boxShadow: '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              lineHeight: 'normal',
              fontSize: '20px',
              color: '#020202',
              marginBottom: '10px',
              textAlign: 'center',
            }}
          >
            Brain-Boosting Daily Routine
          </Typography>
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                padding: '10px 15px',
                background: '#F3F2F8',
                borderRadius: '8px',
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Box>
                <Checkmark />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  lineHeight: 'normal',
                  fontSize: '16px',
                  color: 'rgba(2, 2, 2, 0.50)',
                  span: {
                    color: '#020202',
                    fontFamily: 'Avenir600',
                  },
                }}
              >
                {el}
              </Typography>
            </Box>
          ))}

          <Box height={'5px'} />
          <QuizButton
            onClick={() => {
              handleGoTo(Routes.WEBAPP_ARTICLES);
            }}
            variant={ButtonType.MAIN}
            text={''}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <TipsIcon />
              More tips
            </Box>
          </QuizButton>
        </Box>
        <Divider />
        <Box
          sx={{
            background: '#fff',
            borderRadius: '20px',
            boxShadow: '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              lineHeight: 'normal',
              fontSize: '20px',
              color: '#020202',
              marginBottom: '10px',
            }}
          >
            Shocking Brain Fact:
          </Typography>
          <Box
            sx={{
              padding: '20px',
              background: '#F3F2F8',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '16px',
                color: 'rgba(2, 2, 2, 0.50)',
                span: {
                  color: '#020202',
                  fontFamily: 'Avenir600',
                },
              }}
            >
              Your brain can hold more information than the entire internet - with an estimated storage capacity of{' '}
              <span>2.5 petabytes</span> (that’s about 3 million hours of TV)!
            </Typography>
          </Box>
          <QuizButton
            onClick={() => {
              handleGoTo(Routes.WEBAPP_ARTICLES);
            }}
            variant={ButtonType.MAIN}
            text={''}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <BrainIcon />
              Tell me more
            </Box>
          </QuizButton>
        </Box>
      </Box>

      <Box
        sx={{
          padding: '40px 20px 0',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <QuizButton
          onClick={() => {
            closeUnsubscribe();
          }}
          variant={ButtonType.MAIN}
          text={'Keep me subscribed'}
        />

        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          I still want to opt out
        </Typography>
      </Box>
    </Box>
  );
}

export default Step5;
