import { useCallback, useState } from 'react';
import { cancelSubscription, ISubscription } from '@api/requests';
import Checkmark from '@assets/svg/webapp/unsubscribe/updated_checkmark.svg';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import P13 from '@assets/svg/webapp/unsubscribe/p13.svg';
import P14 from '@assets/svg/webapp/unsubscribe/p14.svg';
import P15 from '@assets/svg/webapp/unsubscribe/p15.svg';
import P16 from '@assets/svg/webapp/unsubscribe/p16.svg';
import P17 from '@assets/svg/webapp/unsubscribe/p17.svg';
import RedCheckmark from '@assets/svg/webapp/unsubscribe/red_checkmark.svg';
import StepHeader from '../components/StepHeader';
import { useWebAppState } from '@components/WebApp/WebApp';
import { APP_CONSTANTS } from '@utils/constants';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { trackEvent } from '@api/requests';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

const points = [
  {
    title: 'Your true potential',
    subTitle: 'Get a real insights into your cognitive strengths',
    img: <P13 />,
  },
  {
    title: 'Smart games to boost IQ',
    subTitle: 'Scientifically designed for a sharp mind',
    img: <P14 />,
  },
  {
    title: 'Strategies to think smarter',
    subTitle: 'Expert insights at your fingertips',
    img: <P15 />,
  },
  {
    title: 'Ongoing Mental Growth',
    subTitle: 'Memory, problem-solving, and critical thinking',
    img: <P16 />,
  },
  {
    title: 'Improve & learn every day',
    subTitle: 'Even without connection',
    img: <P17 />,
  },
];

function Step9({
  userSubscription,
  checkUserSubscriptions,
  setStep,
  isHasGiftOneMonthSubscription,
}: {
  userSubscription: ISubscription | null;
  checkUserSubscriptions: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isHasGiftOneMonthSubscription: boolean;
}) {
  const { closeUnsubscribe } = useWebAppState();
  const [isUsubscribed, setUnsubscribed] = useState(false);
  const theme = useTheme();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { userLocation } = useQuizState();
  const { cfTrackHeader } = usePaymentState();
  const handleContactUs = () => {
    window.open(`mailto:${APP_CONSTANTS.EMAIL}`, '_self');
  };

  const handleCancelSubscription = () => {
    if (isLoading) return;
    trackEvent('Cancel subscription click', cfTrackHeader, userLocation, {
      host: window.location.host,
      project: 'lpr_web',
    });

    if (userSubscription) {
      const subscriptionId = userSubscription.id;

      setLoading(true);
      cancelSubscription(subscriptionId, cfTrackHeader)
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
            trackEvent('Cancel subscription error', cfTrackHeader, userLocation, {
              error: JSON.stringify(response.error),
              subscription_id: subscriptionId,
              host: window.location.host,
              project: 'lpr_web',
            });
          } else {
            if (response.success) {
              setUnsubscribed(true);
              checkUserSubscriptions();
              trackEvent('Cancel subscription success', cfTrackHeader, userLocation, {
                subscription_id: subscriptionId,
                host: window.location.host,
                project: 'lpr_web',
              });
            } else {
              setError('Something went wrong');
              trackEvent('Cancel subscription unknown response', cfTrackHeader, userLocation, {
                response: JSON.stringify(response),
                subscription_id: subscriptionId,
                host: window.location.host,
                project: 'lpr_web',
              });
            }
          }
        })
        .catch((error) => {
          console.error('cancelSubscriptions error', error);
          const errorMessage = typeof error === 'string' ? error : (error.error ?? error.message);
          setError(typeof errorMessage === 'string' ? errorMessage : 'Something went wrong');

          trackEvent('Cancel subscription request error', cfTrackHeader, userLocation, {
            error: JSON.stringify(error),
            errorMessage,
            subscription_id: subscriptionId,
            host: window.location.host,
            project: 'lpr_web',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('Cant find user subscription');
      trackEvent('Cant find user subscription', cfTrackHeader, userLocation, {
        error: 'Cant find user subscription',
        host: window.location.host,
        project: 'lpr_web',
      });
    }
  };

  const handleBack = useCallback(() => {
    if (isHasGiftOneMonthSubscription) {
      setStep((prev) => prev - 2);
    } else {
      setStep((prev) => prev - 1);
    }
  }, [setStep, isHasGiftOneMonthSubscription]);

  return (
    <Box>
      <StepHeader onClick={handleBack} title="Don’t lose" />
      <Box
        sx={{
          padding: '20px 20px 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '5px',
            span: {
              color: '#E53E3E',
            },
          }}
        >
          Your Brain Gains Are About to <span>Disappear</span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Before you finalize your cancellation, here’s what you’ll be leaving behind:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                background: 'rgba(229, 62, 62, 0.07)',
                borderRadius: '16px',
                padding: '15px',
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  svg: {
                    width: '32px',
                    height: '32px',
                  },
                }}
              >
                {el.img}
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    lineHeight: 'normal',
                    fontSize: '16px',
                    color: theme.palette.text.primary,
                  }}
                >
                  {el.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    lineHeight: 'normal',
                    fontSize: '14px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {el.subTitle}
                </Typography>
              </Box>
              <Box
                sx={{
                  svg: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                <RedCheckmark />
              </Box>
            </Box>
          ))}
        </Box>

        {error && (
          <span
            style={{
              color: 'red',
              marginBottom: '15px',
            }}
          >
            {error}
          </span>
        )}
        <QuizButton
          onClick={() => {
            closeUnsubscribe();
          }}
          variant={ButtonType.MAIN}
          text={'Stay a premium user'}
        />

        <Typography
          onClick={handleCancelSubscription}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={16}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          ) : (
            'Give up my brain power & cancel'
          )}
        </Typography>
      </Box>

      {isUsubscribed && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 9999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#23263160',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              background: '#FFF',
              borderRadius: '20px 20px 0px 0px',
              boxShadow: '0px 5px 15px 0px rgba(106, 126, 149, 0.05)',
              padding: '30px 20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '500px',
              svg: {
                width: '82px',
                height: '82px',
              },
            }}
          >
            <Checkmark />

            <Typography
              sx={{
                fontFamily: 'Avenir600',
                lineHeight: 'normal',
                fontSize: '24px',
                color: theme.palette.text.primary,
                marginTop: '20px',
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              Your membership has been canceled
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '16px',
                color: theme.palette.text.secondary,
                marginBottom: '20px',
                textAlign: 'center',
              }}
            >
              We'll miss you! Truly appreciate you being part of our community.
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
                textAlign: 'center',
                borderRadius: '20px',
                padding: '20px',
                marginBottom: '20px',
                background: '#F3F2F8',
                span: {
                  fontFamily: 'Avenir600',
                  color: 'var(--primary-color)',
                },
              }}
            >
              While your subscription has ended, your Instagram growth doesn’t have to. If you ever want to come back,
              we’d love to welcome you at a <span>special discounted price.</span>
            </Typography>
            <QuizButton
              onClick={() => {
                closeUnsubscribe();
              }}
              variant={ButtonType.MAIN}
              text={'Continue'}
            />

            <Button
              onClick={handleContactUs}
              sx={{
                marginTop: '10px',
                border: 'none',
                borderRadius: '12px',
                color: theme.palette.text.primary,
                height: '60px',
                fontWeight: 600,
                lineHeight: 'normal',
                fontSize: '16px',
                width: '100%',
                cursor: 'pointer',
                textTransform: 'initial',
                fontFamily: 'Avenir600',
                transition: 'all 0.3s ease',
                background: '#F3F2F8',
                WebkitTapHighlightColor: 'transparent',
                '&:hover': {
                  background: '#F3F2F8',
                },
              }}
            >
              Contact us
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Step9;
