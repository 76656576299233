import { Box } from '@mui/material';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import { useEffect } from 'react';
import { trackEvent } from '@api/requests';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';
import { PixelEvents } from '@constants/events';
import PaymentModal from './components/PaymentModal/PaymentModal';
import { trackPixelEvent } from '@services/analytics/trackEvent';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function Subscription() {
  const { userLocation } = useQuizState();
  const { cfTrackHeader } = usePaymentState();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPixelEvent(PixelEvents.CompleteRegistration);
    trackEvent('sub screen shown', cfTrackHeader, userLocation);
    wmq.track(EvTruck.SubscriptionShown);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '112px',
      }}
    >
      <Header />
      <MainContent />
      <Footer />
      <PaymentModal />
    </Box>
  );
}

export default Subscription;
