import { trackEvent } from '@api/requests';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { useCallback, useMemo, useState } from 'react';
import { Routes } from '../../../App';
import AreYouSureModal from '../components/AreYouSureModal';

function Report({ setStepNumber }: { setStepNumber: React.Dispatch<React.SetStateAction<number>> }) {
  const {
    isAdditionalPaymentLoading,
    additionalPaymentError,
    handleAdditionalOfferAdd,
    setAdditionalPaymentError,
    cfTrackHeader,
  } = usePaymentState();
  const { analysisReportSalePlan, showAdditionalOffersPrice, analysisReportSale2Plan, showSkipPopup } =
    usePaymentConfig();
  const { userLocation } = useQuizState();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSkip = () => {
    trackEvent(`Skip ${analysisReportSalePlan?.name} click`, cfTrackHeader, userLocation).catch(console.error);
    if (analysisReportSale2Plan && showSkipPopup) {
      setModalOpen(true);
      setAdditionalPaymentError('');
    } else {
      setStepNumber((prev) => prev + 1);
    }
  };

  const handleGetTheOffer = useCallback(() => {
    if (isAdditionalPaymentLoading) return;

    if (analysisReportSalePlan) {
      handleAdditionalOfferAdd(analysisReportSalePlan, () => {
        setStepNumber((prev) => prev + 1);
      });
    }
  }, [analysisReportSalePlan, isAdditionalPaymentLoading]);

  const points = useMemo(() => {
    return [
      {
        title: 'Comprehensive cognitive insights',
        image: 'svg/offer/p5.svg',
      },
      { title: 'Actionable recommendations', image: 'svg/offer/p6.svg' },
      {
        title: 'Strategy for personal growth',
        image: 'svg/offer/p7.svg',
      },
      ...(showAdditionalOffersPrice
        ? [
            {
              title: `One-time payment $${analysisReportSalePlan?.sum}`,
              image: 'svg/offer/p4.svg',
            },
          ]
        : []),
    ];
  }, [analysisReportSalePlan, showAdditionalOffersPrice]);

  const planPrice = analysisReportSalePlan?.sum ?? 0;
  const salePlanPrice = analysisReportSale2Plan?.sum ?? 0;
  const amountToSave = planPrice - salePlanPrice;

  const handleGetTheOfferModal = useCallback(() => {
    trackEvent(`Get The Offer Modal Click`, cfTrackHeader, userLocation);
    if (analysisReportSale2Plan) {
      handleAdditionalOfferAdd(analysisReportSale2Plan, () => {
        setStepNumber((prev) => prev + 1);
      });
    }
  }, [analysisReportSale2Plan]);

  const handleSkipOfferModal = useCallback(() => {
    trackEvent(`Skip This Offer Modal Click`, cfTrackHeader, userLocation);

    setAdditionalPaymentError('');

    setStepNumber((prev) => prev + 1);
  }, []);

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <img width={128} src="images/subscription/report_chart.webp" alt="decor"></img>
      <Typography
        sx={{
          margin: '20px 0 10px',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
          span: {
            color: '#6639E6',
          },
        }}
      >
        Unlock in-depth <span>cognitive analysis report</span>
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '16px',
          lineHeight: 'normal',
          color: 'rgba(2, 2, 2, 0.50)',
          textAlign: 'center',
        }}
      >
        Start your cognitive growth with our personalized report
      </Typography>
      <Box
        sx={{
          padding: '20px',
          borderRadius: ' 20px',
          background: '#F3F2F8',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          margin: '20px 0',
        }}
      >
        {points.map((el) => (
          <Box
            key={el.title}
            sx={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <img src={el.image} alt="point"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#020202',
              }}
            >
              {el.title}
            </Typography>
          </Box>
        ))}
      </Box>

      {additionalPaymentError && (
        <Typography
          sx={{
            margin: '10px 20px',
            color: '#E03045',
            textAlign: 'center',
          }}
        >
          {additionalPaymentError}
        </Typography>
      )}
      <QuizButton onClick={handleGetTheOffer} variant={ButtonType.MAIN} text="">
        {isAdditionalPaymentLoading ? (
          <CircularProgress size={20} style={{ color: 'white' }}></CircularProgress>
        ) : (
          <>Get my personalized report</>
        )}
      </QuizButton>
      <Typography
        onClick={handleSkip}
        sx={{
          cursor: 'pointer',
          fontFamily: 'Avenir400',
          fontSize: '14px',
          lineHeight: 'normal',
          color: '#A9A7AF',
          textAlign: 'center',
          marginTop: '15px',
          textDecoration: 'underline',
        }}
      >
        Skip this offer and proceed further
      </Typography>

      {!showAdditionalOffersPrice && (
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            color: '#A9A7AF',
            textAlign: 'center',
            marginTop: '15px',
            '& .link': {
              color: '#A9A7AF',
              textDecorationColor: '#A9A7AF',
            },
          }}
        >
          By clicking the button, I agree to enroll in a one-time subscription at the cost of $
          {analysisReportSalePlan?.sum}. I have thoroughly reviewed and accepted the{' '}
          <Link target="_blank" href={Routes.TERMS} className="link">
            Terms and Conditions.
          </Link>
        </Typography>
      )}

      <AreYouSureModal
        isModalOpen={isModalOpen}
        amountToSave={amountToSave}
        onGetClick={handleGetTheOfferModal}
        onSkipClick={handleSkipOfferModal}
        isLoading={isAdditionalPaymentLoading}
        btnText="Get the offer"
        subTitle={
          <>Unlock cognitive growth: Get insights and boost your skills for only ${analysisReportSale2Plan?.sum}.</>
        }
        points={[
          <>
            Discover your <span>cognitive strengths</span> and areas needing improvement
          </>,
          <>
            Receive <span>tailored steps</span> to boost your cognitive abilities
          </>,
          <>
            Get <span>personalized strategies</span> aimed at improving your mental performance
          </>,
        ]}
      />
    </Box>
  );
}

export default Report;
