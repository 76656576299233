import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Dialog } from '@mui/material';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import Step7 from './steps/Step7';
import Step8 from './steps/Step8';
import Step9 from './steps/Step9';
import { useWebAppState } from '@components/WebApp/WebApp';

export type UnsubsAnswersType = {
  select: string | null;
  stars: number | null;
  feedback: string;
};

function UnsubscribeModal() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState(0);
  const {
    isUnsubscribeOpen,
    userSubscription,
    isHasPremiumOneMonthSubscription,
    isHasGiftOneMonthSubscription,
    checkUserSubscriptions,
  } = useWebAppState();
  const [unsubAnswers, setUnsubAnswers] = useState<UnsubsAnswersType>({
    select: null,
    stars: null,
    feedback: '',
  });

  useEffect(() => {
    wrapperRef.current?.scrollIntoView();
  }, [step]);

  const steps = useMemo(() => {
    return [
      <Step1 setStep={setStep} />,
      <Step2 setStep={setStep} />,
      <Step3
        setStep={setStep}
        userSubscription={userSubscription}
        isHasPremiumOneMonthSubscription={isHasPremiumOneMonthSubscription}
      />,
      <Step4 setStep={setStep} checkUserSubscriptions={checkUserSubscriptions} />,
      <Step5 setStep={setStep} />,
      <Step6 setStep={setStep} unsubAnswers={unsubAnswers} setUnsubAnswers={setUnsubAnswers} />,
      <Step7
        setStep={setStep}
        unsubAnswers={unsubAnswers}
        setUnsubAnswers={setUnsubAnswers}
        isHasGiftOneMonthSubscription={isHasGiftOneMonthSubscription}
      />,
      <Step8 setStep={setStep} checkUserSubscriptions={checkUserSubscriptions} />,
      <Step9
        setStep={setStep}
        userSubscription={userSubscription}
        checkUserSubscriptions={checkUserSubscriptions}
        isHasGiftOneMonthSubscription={isHasGiftOneMonthSubscription}
      />,
    ];
  }, [
    unsubAnswers,
    isHasGiftOneMonthSubscription,
    userSubscription,
    isHasPremiumOneMonthSubscription,
    checkUserSubscriptions,
  ]);

  const renderStep = useMemo(() => {
    return steps[step];
  }, [steps, step]);

  return (
    <Dialog fullScreen open={isUnsubscribeOpen}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <Box
          ref={wrapperRef}
          sx={{
            height: '100%',
            maxWidth: '500px',
            width: '100%',
          }}
        >
          {renderStep}
        </Box>
      </Box>
    </Dialog>
  );
}

export default UnsubscribeModal;
