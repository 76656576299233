import { QP } from '@constants/queryParams';
import { Routes } from '../App';
import { getParamByKey } from './common';
import { getParamsFromSessionStorage } from './storage';
import { getHost } from "@services/hostUtils";

export const isOnAppDomain = window.location.host.includes('cognifi.one')

// export const redirectToPaymentPage = (email: string, userIqScore: string) => {
//   const params = new URLSearchParams(window.location.search);
//   params.set('redirectDomain', window.location.origin);
//   params.set('redirectUserEmail', email);
//   params.set('redirectIqScore', userIqScore);


//   const idfm = localStorage.getItem("@RNMV/IDFM");

//   if (idfm) {
//     params.set('_idfm', idfm);
//   }


//   const redirectPaymentDomain = () => {
//     switch (true) {
//       case (window.location.host.includes('dev.')): {
//         return 'https://palmistry-dev.devroqapps.com'
//       }
//       case (window.location.host.includes('stg.')): {
//         return 'https://palmistry-stg.devroqapps.com'
//       }
//       default: {
//         return 'https://palmistry.devroqapps.com'
//       }
//     }
//   }

//   window.open(
//     `${redirectPaymentDomain()}${Routes.SUBSCRIPTION}?${params}`,
//     '_self'
//   );
// };

export const getRedirectAppDomain = () => {
  switch (true) {
    case (window.location.host.includes('localhost:3000')): {
      return 'http://localhost:3000'
    }
    case (window.location.host.includes('dev.')): {
      return 'https://dev.cognifi.one'
    }
    case (window.location.host.includes('stg.')): {
      return 'https://stg.cognifi.one'
    }
    default: {
      return 'https://cognifi.one'
    }
  }
}

export const getRedirectToTestUrl = () => {
  const search = {
    'cognifi.pro': '?plan=cognifi_pro_weekly_trial3&ult_pack=cognifi_pro_ultimate_pack_otp&early_access=cognifi_pro_early_access_otp&analysis_report=cognifi_pro_extended_analysis_report_otp&early_access_s=cognifi_pro_early_access_sale_otp&analysis_report_s=cognifi_pro_extended_analysis_report_sale_otp&offline_access_s=cognifi_pro_offline_access_sale_otp&additional_offer_dc=true&light_payment=true&bb_android=yes&payby=9'
  }[getHost(2)] ?? '?plan=cognifi_weekly_trial3&ult_pack=cognifi_ultimate_pack_otp&early_access=cognifi_early_access_otp&analysis_report=cognifi_extended_analysis_report_otp&early_access_s=cognifi_early_access_sale_otp&analysis_report_s=cognifi_extended_analysis_report_sale_otp&offline_access_s=cognifi_offline_access_sale_otp&additional_offer_dc=true&light_payment=true&bb_android=yes&payby=9';
  let params = new URLSearchParams(search);
  const currentParams = new URLSearchParams(window.location.search);
  const wmqParameter = currentParams.get('wmq');

  if (wmqParameter === '1') {
    params.set('wmq', wmqParameter)
  }

  if (currentParams.toString()) {
    params = currentParams
  }

  switch (true) {
    case (window.location.host.includes('dev.')): {
      return `https://dev.trackbeat.app/cognifi/quiz?${params.toString()}`
    }
    case (window.location.host.includes('stg.')): {
      return `https://stg.trackbeat.app/cognifi/quiz?${params.toString()}`
    }
    default: {
      return `https://trackbeat.app/cognifi/quiz?${params.toString()}`
    }
  }
}

export const redirectToAppLoginRecurly = (
) => {
  const redirectSubscriptionId = getParamByKey('redirectSubscriptionId')
  const redirectRecurlyUri = getParamByKey('redirectRecurlyUri')
  const redirectIqScore = getParamByKey('redirectIqScore')
  const redirectTokenId = getParamByKey('redirectTokenId')
  const redirectUserEmail = getParamByKey('redirectUserEmail')
  const redirectAccount = getParamByKey('redirectAccount')

  const sessionPaymentParams = getParamsFromSessionStorage([
    'recurlyToken',
    'userEmail',
    'accountCode',
    'subscriptionId',
    'tokenId',
    'url',
    'iqScore'
  ])

  const searchParams = new URLSearchParams(window.location.search);

  [
    'redirectSubscriptionId',
    'redirectTokenId',
    'redirectUserEmail',
    'redirectRecurlyUri',
    'redirectAccount',
    'redirectIqScore'
  ].forEach((param) => {
    searchParams.delete(param);
  });

  searchParams.set('redirectSubscriptionId', redirectSubscriptionId ?? sessionPaymentParams.subscriptionId);
  searchParams.set('redirectTokenId', redirectTokenId ?? sessionPaymentParams.tokenId);
  searchParams.set('redirectUserEmail', redirectUserEmail ?? sessionPaymentParams.userEmail);
  searchParams.set('redirectRecurlyUri', redirectRecurlyUri ?? sessionPaymentParams.url);
  searchParams.set('redirectAccount', redirectAccount ?? sessionPaymentParams.accountCode);
  searchParams.set('redirectIqScore', redirectIqScore ?? sessionPaymentParams.iqScore);

  const redirectUrl = `${getRedirectAppDomain()}${Routes.ACCESS_PRODUCT}?${searchParams}`;

  window.open(redirectUrl, '_self');
};

export const redirectToAppLoginSonara = () => {
  const { userEmail, subscriptionId, iqScore } = getParamsFromSessionStorage([
    'userEmail',
    'subscriptionId',
    'iqScore',
  ])

  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set('vUserEmail', userEmail);
  searchParams.set('vSubscriptionId', subscriptionId);
  searchParams.set('redirectIqScore', iqScore);

  const redirectUrl = `${getRedirectAppDomain()}${Routes.ACCESS_PRODUCT}?${searchParams}`;

  window.open(redirectUrl, '_self');
};

// export function getActiveSubscriptionConfig() {
//   const pathAsArray = window.location.pathname.split('/').filter(Boolean);

//   if (pathAsArray.includes('activate-me') || pathAsArray.includes('activate')) {
//     return {
//       account_code: pathAsArray[pathAsArray.length - 1],
//     };
//   } else {
//     return null;
//   }
// }

// export function removeRedirectParamsFromQuery() {
//   const searchParams = new URLSearchParams(window.location.search);

//   [
//     'redirectSubscriptionId',
//     'redirectTokenId',
//     'redirectUserEmail',
//     'redirectRecurlyUri',
//     'redirectAccount',
//     'redirectIqScore'
//   ].forEach((param) => {
//     searchParams.delete(param);
//   });

//   const newUrl = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams : ''}${window.location.hash}`;

//   window.history.replaceState(null, '', newUrl);
// }

// export const redirectBackAfterPayment = (
//   subscriptionId: string,
//   tokenId: string,
//   email: string,
//   planId: string,
//   recurlyUri: string,
//   account: string,
//   iqScore: string
// ) => {
//   const searchParams = new URLSearchParams(window.location.search);

//   const redirectDomain = searchParams.get('redirectDomain');

//   if (redirectDomain) {
//     searchParams.delete('redirectDomain');
//     searchParams.set('redirectSubscriptionId', subscriptionId);
//     searchParams.set('redirectTokenId', tokenId);
//     searchParams.set('redirectUserEmail', email);
//     searchParams.set('redirectRecurlyUri', recurlyUri);
//     searchParams.set('redirectAccount', account);
//     searchParams.set('redirectIqScore', iqScore);

//     const redirectUrl = `${redirectDomain}${Routes.OFFERS}?${searchParams}`;

//     window.open(redirectUrl, '_self');
//   }
// };

export const getPriceWithDiscount = (price: number, discountPercent: number) => {
  return Number((price * 100) / (100 - discountPercent)).toFixed(2);
}

export function removeUnsubscribeQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(QP.UNSUNSCRIPTION);

  const newUrl = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams : ''}${window.location.hash}`;

  window.history.replaceState(null, '', newUrl);
}
