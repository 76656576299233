import { Box, Typography, useTheme } from '@mui/material';
import P1 from '@assets/svg/webapp/unsubscribe/p1.svg';
import P2 from '@assets/svg/webapp/unsubscribe/p2.svg';
import P3 from '@assets/svg/webapp/unsubscribe/p3.svg';
import P4 from '@assets/svg/webapp/unsubscribe/p4.svg';
import P5 from '@assets/svg/webapp/unsubscribe/p5.svg';
import PointCheckmark from '@assets/svg/webapp/unsubscribe/point_checkmark.svg';
import StepHeader from '../components/StepHeader';
import { useWebAppState } from '@components/WebApp/WebApp';
import QuizButton, { ButtonType } from '@components/common/QuizButton';

function Step1({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const { closeUnsubscribe } = useWebAppState();
  const theme = useTheme();

  const points = [
    {
      title: 'In-depth IQ assessments',
      subTitle: 'Discover your true cognitive strengths',
      img: <P1 />,
    },
    {
      title: 'Fun brain workouts',
      subTitle: 'Engaging puzzles and logic games',
      img: <P2 />,
    },
    {
      title: 'Brain-boosting library',
      subTitle: 'Expert-backed content on the brain',
      img: <P3 />,
    },
    {
      title: 'Easy cognitive hacks',
      subTitle: 'Train your brain anytime, anywhere',
      img: <P4 />,
    },
    {
      title: 'Continuous mental growth',
      subTitle: 'Build lifelong cognitive resilience',
      img: <P5 />,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        backgroundImage: 'url(/cognifi/images/gradient_background.webp)',
        backgroundSize: '100% 400px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <StepHeader
        onClick={() => {
          closeUnsubscribe();
        }}
        title="My active plan"
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 20px 0',
        }}
      >
        <img
          className="logo"
          width={'135px'}
          height={'132px'}
          src={'/cognifi/images/unsubscribe/step1_image.webp'}
          alt="logo"
        ></img>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            marginTop: '20px',
            marginBottom: '10px',
            textAlign: 'center',
            span: {
              color: 'var(--primary-color)',
            },
          }}
        >
          Stay subscribed & <span>stay smart!</span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Here’s why you might want to reconsider cancelling:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                background: '#F3F2F8',
                borderRadius: '16px',
                padding: '15px',
                position: 'relative',
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-5px',
                  right: '-5px',
                  zIndex: 5,
                  svg: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                <PointCheckmark />
              </Box>
              <Box
                sx={{
                  svg: {
                    width: '32px',
                    height: '32px',
                  },
                }}
              >
                {el.img}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    fontSize: '16px',
                    color: theme.palette.text.primary,
                  }}
                >
                  {el.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    fontSize: '14px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {el.subTitle}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <QuizButton
          onClick={() => {
            closeUnsubscribe();
          }}
          variant={ButtonType.MAIN}
          text={'Stay premium'}
        />
        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          Say goodbye to a sharper mind
        </Typography>
      </Box>
    </Box>
  );
}

export default Step1;
