export const canUseDOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';


declare global {
  interface Window {
    ttq?: any;
  }
}

function execCommand(..._args: any) {
  if (!canUseDOM) return;
  if (window?.ttq) {
    window.ttq.push(arguments);
  }
}

function trackCommand(targetEvent: string, params?: Record<string, any>) {
  if (!canUseDOM) return;
  if (window?.ttq) {
    window.ttq.track(targetEvent, params ?? {});
  }
}

const ttq = {
  track(targetEvent: string, params?: Record<string, any>) {
    trackCommand(targetEvent, params);
  },
  load(targetId: string) {
    execCommand(targetId);
  },
  js(startDate: Date) {
    execCommand('js', startDate);
  },
  page() { },
} as const;

class TikTokManager {
  private tiktokId: string;
  private isInitialized: boolean;

  constructor() {
    this.tiktokId = '';
    this.isInitialized = false;
  }

  public init(tiktokId: string) {
    if (this.isInitialized || !canUseDOM) return;

    this.tiktokId = tiktokId;


    const tiktokScript = document.createElement('script');

    tiktokScript.id = 'tikTok';
    tiktokScript.type = 'text/javascript';

    tiktokScript.innerHTML = `!function (w, d, t) {w.TiktokAnalyticsObject = t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};}(window, document, 'ttq');`;
    document.body.append(tiktokScript);

    if (window.ttq) {
      window.ttq.load(this.tiktokId);
      window.ttq.page();
      this.isInitialized = true;
    }
  }
}

export const tikTokManager = new TikTokManager();

export default ttq;
