import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useWebAppState } from '@components/WebApp/WebApp';
import BaseModal from '@components/WebApp/components/common/BaseModal';
import { cancelSubscritionViaEmail } from '@api/requests';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function CancelSubscriptionModal({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}) {
  const { isUnsubscribeEmailSent, setUnsubscribeEmailSent, userSubscriptionEmail } = useWebAppState();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { cfTrackHeader } = usePaymentState();

  const handleCancelSubscription = () => {
    if (isLoading) return;
    if (userSubscriptionEmail) {
      setLoading(true);
      cancelSubscritionViaEmail({ email: userSubscriptionEmail, cfTrackHeader, inAppUnsubscribe: true })
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          } else {
            if (response.success) {
              setUnsubscribeEmailSent(true);
            } else {
              setError('Something went wrong');
            }
          }
        })
        .catch(async (error) => {
          console.log('cancelSubscription error', error);
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(typeof errorMessage === 'object' ? 'Something went wrong' : JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return isUnsubscribeEmailSent ? (
    <BaseModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} showClose={false}>
      <>
        <img src={'/cognifi/images/unsubscribe/canceled.svg'} alt="logo"></img>
        <Typography
          sx={{
            color: '#020202',
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '20px',
            marginTop: '16px',
          }}
        >
          We’ve sent you a confirmation email
        </Typography>

        <Typography
          sx={{
            color: 'rgba(2, 2, 2, 0.50)',
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            marginTop: '4px',
            marginBottom: '16px',
          }}
        >
          {/* Your plan is active until {'date'}. Thanks for using our product! */}
          Complete cancellation by following a link we emailed to {userSubscriptionEmail}. Be sure to check the spam
          folder too
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            '& button': {
              border: 'none',
              borderRadius: '12px',
              background: '#6639E6',
              padding: '19px 32px',
              color: '#fff',
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '16px',
              width: '100%',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              WebkitTapHighlightColor: 'transparent',
            },
          }}
        >
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Continue
          </button>
        </Box>
      </>
    </BaseModal>
  ) : (
    <BaseModal isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
      <>
        <img width={105} height={77} src={'/cognifi/images/unsubscribe/heart.webp'} alt="heart"></img>
        <Typography
          sx={{
            color: '#020202',
            fontWeight: 600,
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '20px',
            marginTop: '16px',
          }}
        >
          We are sorry to see you go
        </Typography>

        <Typography
          sx={{
            color: '#020202',
            lineHeight: 'normal',
            fontFamily: 'Avenir400',
            fontSize: '16px',
            marginTop: '4px',
            marginBottom: '16px',
          }}
        >
          If you cancel your membership, you’ll be missing out on all this cool stuff.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            '& .wrapper': {
              display: 'flex',
              gap: '15px',

              '& div': {
                background: '#6639E610',
                borderRadius: '50%',
                minWidth: '56px',
                minHeight: '56px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '& .title': {
                fontSize: '16px',
                color: '#020202',
                fontFamily: 'Avenir400',
                textAlign: 'left',
                lineHeight: 'normal',
                span: {
                  fontFamily: 'Avenir600',
                  color: '#6639E6',
                },
              },
            },
          }}
        >
          <Box className="wrapper">
            <Box>
              <img src={'/cognifi/images/unsubscribe/svg1.svg'} alt="point"></img>
            </Box>
            <Typography className="title">
              <span>IQ assessments</span> with real insights how bright you can shine
            </Typography>
          </Box>

          <Box className="wrapper">
            <Box>
              <img src={'/cognifi/images/unsubscribe/svg2.svg'} alt="point"></img>
            </Box>
            <Typography className="title">
              <span>Smart games</span> that make brain workouts so fun
            </Typography>
          </Box>

          <Box className="wrapper">
            <Box>
              <img src={'/cognifi/images/unsubscribe/svg3.svg'} alt="point"></img>
            </Box>
            <Typography className="title">
              <span>Offline mode</span> for on-the-go genius. No signal needed!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
            '& button': {
              border: 'none',
              borderRadius: '12px',
              background: '#6639E6',
              padding: '19px 32px',
              color: '#fff',
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '16px',
              width: '100%',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              WebkitTapHighlightColor: 'transparent',
            },
          }}
        >
          {error && (
            <span
              style={{
                color: 'red',
                marginBottom: '15px',
              }}
            >
              {error}
            </span>
          )}
          <button
            className="blue"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Count me in! Keep my membership
          </button>
          <Typography
            onClick={handleCancelSubscription}
            sx={{
              fontSize: '16px',
              color: '#A9A7AF',
              textDecoration: 'underline',
              textDecorationColor: '#A9A7AF',
              textDecorationThickness: '1px',
              fontFamily: 'Avenir400',
              lineHeight: 'normal',
              marginTop: '15px',
              cursor: 'pointer',
              WebkitTapHighlightColor: 'transparent',
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={16}
                sx={{
                  color: '#A9A7AF',
                }}
              />
            ) : (
              <>I want to cancel</>
            )}
          </Typography>
        </Box>
      </>
    </BaseModal>
  );
}

export default CancelSubscriptionModal;
