import { useCallback } from 'react';
import { useWebAppState } from '@components/WebApp/WebApp';
import { UnsubsAnswersType } from '../UnsubscribeModal';
import { Box, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import CheckMarkSvg from '@assets/svg/webapp/unsubscribe/select_checkmark.svg';
import QuizButton, { ButtonType } from '@components/common/QuizButton';

const answers = [
  'I want to keep my membership',
  'Not using It enough',
  'Not seeing immediate results',
  'My priorities changed',
  'I only wanted a trial',
  "I'm cutting down on expenses",
  'Looking for a free alternative',
  'Other',
];

function Step6({
  setStep,
  unsubAnswers,
  setUnsubAnswers,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  unsubAnswers: UnsubsAnswersType;
  setUnsubAnswers: React.Dispatch<React.SetStateAction<UnsubsAnswersType>>;
}) {
  const theme = useTheme();
  const { closeUnsubscribe } = useWebAppState();

  const handleContinue = useCallback(() => {
    if (unsubAnswers.select === answers[0]) {
      closeUnsubscribe();
    } else {
      setStep((prev) => prev + 1);
    }
  }, [unsubAnswers, setStep, closeUnsubscribe]);

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Your Membership"
      />
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '5px',
          }}
        >
          What made you change your mind about the membership?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            margin: '30px 0',
          }}
        >
          {answers.map((el, i) => (
            <Box
              onClick={() => {
                setUnsubAnswers((prev) => ({ ...prev, select: el }));
              }}
              key={i}
              sx={{
                WebkitTapHighlightColor: 'transparent',
                cursor: 'pointer',
                height: '60px',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                outline: el === unsubAnswers.select ? undefined : '1px solid rgba(2, 2, 2, 0.10)',
                background: el === unsubAnswers.select ? '#E5E1FC' : '#FFF',
                padding: '0 20px',
                svg: {
                  width: '24px',
                  height: '24px',
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  lineHeight: 'normal',
                  fontSize: '16px',
                  color: theme.palette.text.primary,
                }}
              >
                {el}
              </Typography>
              {el === unsubAnswers.select ? (
                <CheckMarkSvg />
              ) : (
                <Box
                  sx={{
                    border: '1px solid rgba(141, 104, 71, 0.20)',
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <QuizButton
          disabled={unsubAnswers.select === null}
          onClick={handleContinue}
          variant={ButtonType.MAIN}
          text={'Continue'}
        />
      </Box>
    </Box>
  );
}

export default Step6;
