import ttq from "./tiktok";

declare global {
  interface Window {
    ttq?: any;
    fbq?: any;
  }
}
export const trackPixelEvent = (event: string, data?: any) => {
  try {
    if (window.fbq) {
      window.fbq('track', event, data)
    }
  } catch (e) {
    console.warn('Could not track standard pixel event: ', event);
  }

  ttq.track(event, data);
};
