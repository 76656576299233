import React from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Routes } from '../../App';
import { IQReport } from '@models/webapp';
import { WEBAPP_IMAGE_PATHS } from '@utils/imagePathsManual';
import { preloadImage } from '@hooks/useImagePreloader';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { firebaseAuth } from '@services/firebase';
import { getProfile, getUserSubscriptions, ISubscription } from '@api/requests';
import { useUser } from '@providers/UserProvider';
import { getPathWithSearchParams } from '@utils/ulr';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { startLogoutTimer } from '@utils/logout';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import UnsubscribeModal from './components/Unsubscribe/UnsubscribeModal';
import { removeUnsubscribeQueryParams } from '@utils/payment';
import { QP } from '@constants/queryParams';
import {
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS,
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS,
} from '@constants/subscriptions';

const DefaultWrapper = styled.div`
  max-width: 460px;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--grey-light-color);
  background-image: url(/cognifi/images/gradient_background.webp);
  background-size: 100% 400px;
  background-repeat: no-repeat;
`;

const ProfileWrapper = styled(DefaultWrapper)`
  background-color: white;
  background-image: url(/cognifi/images/gradient_background.webp);
  background-size: 100% 400px;
`;

const CompareWrapper = styled(DefaultWrapper)`
  background-color: white;
  background-image: url(/cognifi/images/gradient_background_fullscreen.webp);
  background-size: cover;
`;

const ProfileReportWrapper = styled(DefaultWrapper)`
  background-color: white;
`;

interface IWebAppContext {
  reportLoading: boolean;
  report: IQReport | null;
  setReport: React.Dispatch<React.SetStateAction<IQReport | null>>;
  setReportLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openIqCertificate: boolean;
  setOpenIqCertificate: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionId: string | null;
  isSubscriptionCanceled: boolean;
  subscriptionStartedAt: string | null;
  userSubscriptionEmail: string | null;
  userSubscription: ISubscription | null;
  isUnsubscribeOpen: boolean;
  closeUnsubscribe: () => void;
  isUnsubscribeEmailSent: boolean;
  setUnsubscribeEmailSent: (s: boolean) => void;
  isHasPremiumOneMonthSubscription: boolean;
  isHasGiftOneMonthSubscription: boolean;
  checkUserSubscriptions: () => void;
}

const WebAppContext = createContext<IWebAppContext | null>(null);

export const useWebAppState = () => {
  const context = useContext(WebAppContext);

  if (!context) {
    throw new Error('useWebAppState must be used within the WebAppContext');
  }

  return context;
};

export default function WebApp() {
  const [report, setReport] = useState<IQReport | null>(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [openIqCertificate, setOpenIqCertificate] = useState<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { paymentConfig } = usePaymentConfig();
  const { setLoading, cfTrackHeader } = usePaymentState();
  const { setUser, setProfile } = useUser();
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [isSubscriptionCanceled, setSubscriptionCanceled] = useState(false);
  const [subscriptionStartedAt, setSubscriptionStartedAt] = useState<string | null>(null);
  const [userSubscriptionEmail, setUserSubscriptionEmail] = useState<string | null>(null);
  const [userSubscription, setUserSubscription] = useState<ISubscription | null>(null);
  const [isUnsubscribeOpen, setUnsubscribeOpen] = useState(false);
  const [isUnsubscribeEmailSent, setUnsubscribeEmailSent] = useState(false);
  const [isHasPremiumOneMonthSubscription, setHasPremiumOneMonthSubscription] = useState(false);
  const [isHasGiftOneMonthSubscription, setHasGiftOneMonthSubscription] = useState(false);

  const BackgroundWrapper = useMemo(() => {
    switch (pathname) {
      case Routes.WEBAPP_PROFILE: {
        return ProfileWrapper;
      }
      case Routes.WEBAPP_COMPARE: {
        return CompareWrapper;
      }
      case Routes.WEBAPP_ARTICLES:
      case Routes.WEBAPP_PROFILE_REPORT: {
        return ProfileReportWrapper;
      }
      default: {
        return DefaultWrapper;
      }
    }
  }, [pathname]);

  useEffect(() => {
    const prelodImg = async () => {
      const imagesPromiseList: Promise<any>[] = [];

      for (const i of WEBAPP_IMAGE_PATHS) {
        imagesPromiseList.push(preloadImage(i));
      }

      const promiseSettledResult = await Promise.allSettled(imagesPromiseList);

      const rejected = promiseSettledResult.find((promise) => promise.status === 'rejected');
      if (rejected) {
        console.error('Some webapp images was not loaded: ', rejected);
      } else {
        console.error('Webapp Images loaded');
      }
    };
    prelodImg();
  }, []);

  const checkUserSubscriptions = () => {
    const user = firebaseAuth.currentUser;
    if (user) {
      const isAppleAuth = user.providerData[0].providerId === 'apple.com';
      const userId = isAppleAuth ? user.providerData[0].uid : user.uid;
      const recurlyUri = paymentConfig.recurly.uri;
      getUserSubscriptions(userId, recurlyUri, cfTrackHeader)
        .then(async ({ subscriptions }) => {
          const sortedByDateSubscriptions = subscriptions?.sort(
            (a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
          );

          const activeMainSubscription = sortedByDateSubscriptions.find((subscription) => {
            return subscription.bundle === 'cognifi' && subscription.main_plan && subscription.status === 'active';
          });
          const canceledMainSubscription = sortedByDateSubscriptions.find((subscription) => {
            return subscription.bundle === 'cognifi' && subscription.main_plan && subscription.status === 'canceled';
          });

          const isHasPremiumOneMonthSubscription = sortedByDateSubscriptions.find((subscription) => {
            return (
              subscription.bundle === 'cognifi' &&
              PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
              subscription.status !== 'expired'
            );
          });
          if (isHasPremiumOneMonthSubscription) {
            setHasPremiumOneMonthSubscription(true);
          }

          const isHasGiftOneMonthSubscription = sortedByDateSubscriptions.find((subscription) => {
            return (
              subscription.bundle === 'cognifi' &&
              GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
              subscription.status !== 'expired'
            );
          });
          if (isHasGiftOneMonthSubscription) {
            setHasGiftOneMonthSubscription(true);
          }
          const userSubscription = activeMainSubscription ?? canceledMainSubscription;

          if (userSubscription) {
            setUserSubscription(userSubscription);
            setSubscriptionId(userSubscription.id);
            setSubscriptionStartedAt(userSubscription.started_at);
            setSubscriptionCanceled(userSubscription.status === 'canceled');
            setUserSubscriptionEmail(userSubscription.emails[0]);
            user
              .getIdToken(true)
              .then((token) => {
                getProfile(user.uid, token)
                  .then((response) => {
                    setProfile(response);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          } else {
            navigate(Routes.SIGN_IN);
            signOut(firebaseAuth);
            setUser(null);
          }
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined = undefined;
    onAuthStateChanged(firebaseAuth, (user) => {
      if (!user) {
        navigate(getPathWithSearchParams(Routes.SIGN_IN));
      } else {
        interval = startLogoutTimer(() => {
          signOut(firebaseAuth);
          setUser(null);
          navigate(Routes.SIGN_IN);
          setLoading(false);
        });

        checkUserSubscriptions();
      }
    });

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const isUnsubscribe = new URLSearchParams(window.location.search).get(QP.UNSUNSCRIPTION) === 'true';
    setUnsubscribeOpen(isUnsubscribe);
  }, []);

  const closeUnsubscribe = () => {
    setUnsubscribeOpen(false);
    removeUnsubscribeQueryParams();
  };

  const value = {
    report,
    reportLoading,
    setReport,
    setReportLoading,
    openIqCertificate,
    setOpenIqCertificate,
    subscriptionId,
    isSubscriptionCanceled,
    subscriptionStartedAt,
    userSubscriptionEmail,
    userSubscription,
    isUnsubscribeOpen,
    closeUnsubscribe,
    isUnsubscribeEmailSent,
    setUnsubscribeEmailSent,
    isHasPremiumOneMonthSubscription,
    isHasGiftOneMonthSubscription,
    checkUserSubscriptions,
  };

  return (
    <WebAppContext.Provider value={value}>
      <BackgroundWrapper>
        <Header />
        <Outlet />

        <UnsubscribeModal />
      </BackgroundWrapper>
    </WebAppContext.Provider>
  );
}
