import { Box, CircularProgress, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';

function ChoosePlan() {
  const { setQuizData } = useQuizState();

  const { selectedPlan } = usePaymentConfig();

  const {
    isReadyAppleButton,
    isPaymentLoading,
    applePayError,
    handleSonaraPayByApple,
  } = usePaymentState();

  const handleAppleButtonClick = () => {
    if (isPaymentLoading) return;
    handleSonaraPayByApple();
  };

  return (
    <Box id="payment_block">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          margin: '15px 0',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '16px',
            lineHeight: 'normal',
            userSelect: 'none',
            color: '#020202',
            width: '100%',
          }}
        >
          Total due today:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '16px',
              lineHeight: 'normal',
              userSelect: 'none',
              color: '#020202',
              span: {
                color: '#E53E3E',
                marginRight: '5px',
                textDecoration: 'line-through',
              },
            }}
          >
            <span>$9.99</span>${selectedPlan.trial_sum}
          </Typography>
          <Box
            sx={{
              padding: '5px 10px',
              borderRadius: '20px',
              background: '#E53E3E',
              width: 'max-content',
              alignSelf: 'flex-end',
              marginTop: '5px',
              display: 'flex',
            }}
          >
            <img src="svg/fire.svg" alt="fire"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '14px',
                color: '#fff',
                marginLeft: '6px',
              }}
            >
              You just saved 90%
            </Typography>
          </Box>
        </Box>
      </Box>
      {applePayError && (
        <Typography
          sx={{
            marginBottom: '20px',
            color: '#E03045',
            fontFamily: 'Avenir500',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {applePayError}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {isReadyAppleButton && (
          <QuizButton onClick={handleAppleButtonClick} variant={ButtonType.APPLE} text={''}>
            {isPaymentLoading ? (
              <CircularProgress style={{ color: 'white' }}></CircularProgress>
            ) : (
              <>
                Buy with <img style={{ marginLeft: '10px' }} src="svg/apple_pay.svg" alt="apple pay"></img>
              </>
            )}
          </QuizButton>
        )}

        <QuizButton
          onClick={() => {
            setQuizData((prev) => ({
              ...prev,
              isPaymentModalOpen: true,
            }));
          }}
          variant={ButtonType.MAIN}
          text="Credit or debit card"
          withImage="svg/credit_card.svg"
          imageWidth={24}
          imageGap
        ></QuizButton>
      </Box>

      <Box
        sx={{
          border: '1px solid #E6E7EB',
          borderRadius: '12px',
          padding: '20px',
          marginTop: '20px',
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',
            background: '#F3F2F8',
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            width: 'max-content',
            margin: '0 auto 10px',
          }}
        >
          <img draggable={false} src={'svg/green_shield.svg'} width={18} alt="camp" />

          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              lineHeight: 'normal',
              userSelect: 'none',
              color: '#020202',
            }}
          >
            100% money back guarantee
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            textAlign: 'center',
            userSelect: 'none',
            color: 'rgba(2, 2, 2, 0.50)',
          }}
        >
          Your {selectedPlan.name} will cost only ${selectedPlan.trial_sum}. Without cancellation, before the selected
          discounted intro plans ends, I accept that Cognifi will automatically charge ${selectedPlan.sum} every week
          until i cancel.
        </Typography>
      </Box>
    </Box>
  );
}

export default ChoosePlan;
