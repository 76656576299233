import { Box, Typography } from '@mui/material';

function StepHeader({ title, onClick }: { title: string; onClick: () => void }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        height: '57px',
        borderBottom: '1px solid rgba(2, 2, 2, 0.10)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        img: {
          position: 'absolute',
          left: '20px',
          width: '24px',
          height: '24px',
        },
      }}
    >
      <img src={'/cognifi/svg/arrowBack.svg'} alt="arrow back"></img>

      <Typography
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '20px',
          lineHeight: 'normal',
          color: '#232631',
          userSelect: 'none',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default StepHeader;
