import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackEvent } from '@api/requests'
import { Routes } from '../../App'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import QuizButton, { ButtonType } from '../common/QuizButton'
import { getPathWithSearchParams } from '@utils/ulr'
import CommonFooterItems from '@components/common/CommonFooterItems'
import MainHeader from './components/Header'
import { getRedirectToTestUrl, isOnAppDomain } from '@utils/payment'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks'

const keySkills = [
    {
        title: 'Logic',
        subTitle:
            "Enhance problem-solving, critical thinking, and reasoning abilities with our brain games designed to build your logical skills. You'll tackle complex situations and find solutions more easily.",
        image: 'images/main/puzzle_piece.webp',
    },
    {
        title: 'Memory',
        subTitle:
            'Looking to strengthen your memory? Our games will challenge your recall, helping you boost your ability to remember things quickly and accurately.',
        image: 'images/main/cloud_memory.webp',
    },
    {
        title: 'Reaction',
        subTitle:
            'Fast reaction time is a crucial cognitive skill that we rely on daily for safety. By regularly playing our fun and engaging games, you can effectively enhance your reaction speed.',
        image: 'images/main/arrow_icon.webp',
    },
]

export const latestResults = [
    {
        title: 'Robert Fox',
        subTitle: 'a min ago',
        image: 'images/main/group_1.webp',
        flag: 'images/main/united_kingdom.webp',
    },
    {
        title: 'Maria Clara',
        subTitle: 'a min ago',
        image: 'images/main/group_2.webp',
        flag: 'images/main/brazil.webp',
    },
    {
        title: 'DoYoon Choi',
        subTitle: 'a min ago',
        image: 'images/main/group_3.webp',
        flag: 'images/main/south_korea.webp',
    },
    {
        title: 'Tomaž Seliè',
        subTitle: 'a min ago',
        image: 'images/main/group_4.webp',
        flag: 'images/main/slovenia.webp',
    },
    {
        title: 'Matthijs Holvoet',
        subTitle: '2 min ago',
        image: 'images/main/group_5.webp',
        flag: 'images/main/belgium.webp',
    },
]
function Main() {
    const navigate = useNavigate()
    const { userLocation } = useQuizState()
    const { cfTrackHeader } = usePaymentState()

    useEffect(() => {
        trackEvent(`Intro shown`, cfTrackHeader, userLocation)
        wmq.track(EvTruck.IntroShown)
    }, [])

    const handleStartTestClick = () => {
        if (isOnAppDomain) {
            window.open(getRedirectToTestUrl(), '_self')
        } else {
            navigate(getPathWithSearchParams(Routes.QUIZ))
            wmq.track(EvTruck.IntroSuccess)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#F3F2F8',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                backgroundImage: 'url(images/gradient_background.webp)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '460px',
                }}
            >
                <MainHeader />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                        marginTop: '57px',
                    }}
                >
                    <Box
                        sx={{
                            padding: '30px 20px 40px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <img
                                width={'108px'}
                                height={'26px'}
                                src={'svg/logo.svg'}
                                alt="logo"
                            ></img>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginBottom: '10px',
                                span: {
                                    color: 'var(--primary-color)',
                                },
                            }}
                        >
                            Find out your true <span>IQ score</span> in just 3
                            minutes.
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                fontFamily: 'Avenir400',
                                color: 'rgba(2, 2, 2, 0.50)',
                                marginBottom: '20px',
                            }}
                        >
                            Our test, backed by the latest psychological
                            studies, delivers results similar to standardized IQ
                            tests.
                        </Typography>

                        <QuizButton
                            onClick={handleStartTestClick}
                            variant={ButtonType.MAIN}
                            text={'Start Certified Test'}
                        />
                        <Box
                            sx={{
                                margin: '0 -20px',
                            }}
                        >
                            <img
                                width={'100%'}
                                src="images/intro_background.webp"
                                alt="decor"
                            ></img>
                        </Box>

                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginTop: '40px',
                                marginBottom: '20px',
                                span: {
                                    color: 'var(--primary-color)',
                                },
                            }}
                        >
                            The <span>3 key cognitive skills</span> you can
                            enhance:
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                '& .wrapper': {
                                    padding: '30px 20px',
                                    borderRadius: '20px',
                                    background: '#fff',
                                    boxShadow:
                                        '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                },
                                '& img': {
                                    width: '155px',
                                },
                            }}
                        >
                            {keySkills.map((skill) => (
                                <Box key={skill.title} className="wrapper">
                                    <img
                                        src={skill.image}
                                        alt={skill.title}
                                    ></img>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Avenir600',
                                            fontSize: '20px',
                                            color: '#020202',
                                            textAlign: 'center',
                                            userSelect: 'none',
                                            marginTop: '20px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {skill.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Avenir400',
                                            fontSize: '16px',
                                            color: '#020202',
                                            textAlign: 'center',
                                            userSelect: 'none',
                                        }}
                                    >
                                        {skill.subTitle}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            background: '#fff',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginTop: '40px',
                                marginBottom: '20px',
                                span: {
                                    color: 'var(--primary-color)',
                                },
                            }}
                        >
                            <span>Latest results</span> of our users who passed
                            the IQ test
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                padding: '0 20px',
                                '& .wrapper': {
                                    padding: '4px 4px 4px 20px',
                                    borderRadius: '20px',
                                    background: '#F3F2F8',
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            {latestResults.map((result) => (
                                <Box key={result.title} className="wrapper">
                                    <img
                                        width={48}
                                        src={result.flag}
                                        alt={result.title}
                                    ></img>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Avenir600',
                                                fontSize: '16px',
                                                color: '#020202',
                                                userSelect: 'none',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            {result.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Avenir400',
                                                fontSize: '14px',
                                                color: '#020202',
                                                userSelect: 'none',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            {result.subTitle}
                                        </Typography>
                                    </Box>

                                    <img
                                        width={100}
                                        src={result.image}
                                        alt={result.title}
                                    ></img>
                                </Box>
                            ))}
                        </Box>

                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                margin: '40px 20px 10px',
                                span: {
                                    color: 'var(--primary-color)',
                                },
                            }}
                        >
                            What’s the <span>average IQ?</span>
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                fontFamily: 'Avenir400',
                                color: 'rgba(2, 2, 2, 0.50)',
                                margin: '0 20px 20px',
                            }}
                        >
                            The average IQ score across all age groups is 100,
                            the midpoint of the "normal" range. Most scores
                            cluster around this, with fewer as they move further
                            from 100.
                        </Typography>

                        <img
                            style={{
                                padding: '0 20px 40px',
                            }}
                            width={'100%'}
                            src="images/main/chart_image.webp"
                            alt="chart"
                        ></img>
                    </Box>

                    <Box
                        sx={{
                            padding: '40px 20px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir600',
                                fontSize: '24px',
                                color: '#020202',
                                textAlign: 'center',
                                userSelect: 'none',
                                marginBottom: '10px',
                                span: {
                                    color: 'var(--primary-color)',
                                },
                            }}
                        >
                            <span>Find out</span> how you measure up against
                            others
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '16px',
                                fontFamily: 'Avenir400',
                                color: 'rgba(2, 2, 2, 0.50)',
                                marginBottom: '20px',
                            }}
                        >
                            See how your performance compares to the broader
                            population
                        </Typography>
                        <QuizButton
                            onClick={handleStartTestClick}
                            variant={ButtonType.MAIN}
                            text={'Start Certified Test'}
                        />
                    </Box>

                    <Box
                        sx={{
                            padding: '20px',
                            background: '#fff',
                        }}
                    >
                        <CommonFooterItems />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Main
