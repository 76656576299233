import { createContext, FC, FormEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CardValues, PaymentContextType, PaymentPlanType } from './PaymentProvider.types';
import { useRecurly } from '@recurly/react-recurly';
import { createSubscriptionNoUser, finalizeSubscriptionRecurly, saveUserData, trackEvent } from '@api/requests';
import { Routes } from '../../App';
import { useQuizState } from '../QuizProvider/QuizProvider.hooks';
import { PaymentMethodType } from '../QuizProvider/QuizProvider.types';
import { useNavigate } from 'react-router-dom';
import { getParamByKey } from '@utils/common';
import { getPathWithSearchParams } from '@utils/ulr';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useUser } from '../UserProvider';
import { getParamsFromSessionStorage, setParamsToSessionStorage } from '@utils/storage';
import { convertStringToBoolean, getAttribution } from '@utils/attribution';
import {
  isSubscribeRecurlyErrorResponse,
  isSubscribeRecurlySecure3dResponse,
  isSubscribeRecurlySuccessResponse,
} from '@models/recurly';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { PixelEvents } from '@constants/events';
import { firebaseAuth } from '@services/firebase';
import { wmq } from '@services/magnus';
import { EvTruck } from '@models/events';
import { getParamsWithDecreasedValue } from '@utils/facebook';
import { trackPixelEvent } from '@services/analytics/trackEvent';
import SonaraSubscriptions from '../../lib/sonara-subscriptions11';

declare global {
  interface Window {
    ApplePaySession?: typeof Object;
  }
}

export const PaymentContext = createContext<PaymentContextType | null>(null);

export const PaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const trickEvent = convertStringToBoolean(getParamByKey('event_trick') ?? 'false');
  const trickFinalValue = trickEvent ? PixelEvents.Subscribe : PixelEvents.Purchase;
  const { setQuizData, quizData, userLocation, userIqScore } = useQuizState();
  const { paymentConfig, selectedPlan, isFacebookPurchaseEventMustBePostponed, isCognifiPro } = usePaymentConfig();
  const [isReadyAppleButton, setReadyAppleButton] = useState(false);
  const recurly = useRecurly();
  const formRef = useRef<HTMLFormElement>(null);
  const [paymentFormError, setPaymentFormError] = useState<string | null>(null);
  const [applePayError, setApplePayError] = useState<string | null>(null);
  const [error, setError] = useState<null | string>(null);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [isUserSignedIn, setUserSignedIn] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethodType.APPLE);
  const [loading, setLoading] = useState(false);
  const recurlySecureRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useUser();
  const [isAdditionalPaymentLoading, setAdditionalPaymentLoading] = useState(false);
  const [additionalPaymentError, setAdditionalPaymentError] = useState('');

  const redirectEmailParameter = getParamByKey('redirectUserEmail');
  const redirectUserEmail =
    redirectEmailParameter !== '' && redirectEmailParameter !== null
      ? window.decodeURIComponent(redirectEmailParameter)
      : null;

  const isUseCardSonara = new URLSearchParams(window.location.search).get('sonara') === 'true';
  const isUseSonara = useMemo(() => {
    return isUseCardSonara ? true : selectedPaymentMethod === PaymentMethodType.APPLE;
  }, [selectedPaymentMethod, isUseCardSonara]);

  const sonaraEnv =
    process.env.NODE_ENV === 'development' || window.location.origin.includes('dev') ? 'staging' : 'production';

  const subscriptions = new SonaraSubscriptions({
    environment: sonaraEnv,
    // customDomain: 'https://your-custom-domain.com' // опционально
  });

  // console.log('selectedPaymentMethod', selectedPaymentMethod);
  // console.log('isUseSonara', isUseSonara);
  // console.log('subscriptions', subscriptions);

  const cfTrackHeader = useRef(String(Date.now())).current;

  useEffect(() => {
    if (window.ApplePaySession) {
      setReadyAppleButton(true);
    }
  }, [setQuizData]);

  useUpdateEffect(() => {
    const redirectRecurlySubscriptionId = getParamByKey('redirectSubscriptionId');
    const redirectRecurlyUri = getParamByKey('redirectRecurlyUri');
    const redirectIqScore = getParamByKey('redirectIqScore');

    if (user && redirectRecurlySubscriptionId && redirectRecurlyUri) {
      setLoading(true);
      setError(null);

      const appleToken = user.isAppleAuth ? (user.providerDataId ?? user.uid) : user.uid;

      finalizeSubscriptionRecurly({
        cfTrackHeader,
        url: redirectRecurlyUri,
        subscription_id: redirectRecurlySubscriptionId,
        email: user.email,
        firebase_id: !user.isAppleAuth ? appleToken : undefined,
        apple_token: user.isAppleAuth ? appleToken : undefined,
        attribution: getAttribution({
          ip: userLocation?.ip ?? '',
          country: userLocation?.country ?? '',
        }),
      })
        .then(async (response) => {
          if (isSubscribeRecurlySecure3dResponse(response)) {
            /** only for cards **/
            const risk = recurly.Risk();

            const threeDSecure = risk.ThreeDSecure({
              actionTokenId: response.actionTokenId,
            });

            threeDSecure.on('token', function (token) {
              createSubscriptionWithCardToken(token.id).then(() => {
                setPaymentLoading(false);
              });
            });

            threeDSecure.on('error', function (error) {
              console.error('error', error);
            });

            // @ts-ignore
            threeDSecure.attach(recurlySecureRef.current);

            return;
          } else if (isSubscribeRecurlySuccessResponse(response)) {
            let idToken = '';

            const iqScore = redirectIqScore ?? String(115);

            try {
              idToken = (await firebaseAuth.currentUser?.getIdToken(true)) ?? '';
            } catch (err) {
              console.error(err);
            }

            await saveUserData({
              user_id: user.uid,
              iq_value: iqScore,
              subscription_id: user.uid,
              name: user.displayName,
              email: user.email,
              idToken: idToken,
            }).catch((err) => {
              console.log('saveUserData err', err);
            });

            setParamsToSessionStorage({
              subscriptionId: '',
              url: '',
            });
            setUserSignedIn(true);
          } else if (isSubscribeRecurlyErrorResponse(response)) {
            console.error(response.error);
            setError(response.error);
          }
        })
        .catch((err) => {
          if (isSubscribeRecurlyErrorResponse(err)) {
            setError(err.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  const createSubscriptionWithCardToken = (tokenId: string) =>
    selectedPlan
      ? createSubscriptionNoUser({
          cfTrackHeader,
          url: paymentConfig.recurly.uri,
          email: redirectUserEmail ?? quizData.email,
          plan_id: selectedPlan.id,
          token_id: tokenId,
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
        })
          .then(async (response) => {
            if (isSubscribeRecurlyErrorResponse(response)) {
              console.error(response.error);
              setPaymentFormError(response.error);
              setPaymentLoading(false);
            } else if (!isSubscribeRecurlySuccessResponse(response)) {
              console.error(response);
            } else {
              if (!isFacebookPurchaseEventMustBePostponed) {
                const eventData = {
                  email: redirectUserEmail ?? quizData.email,
                  currency: 'USD',
                  value: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
                  transactionId: response.id,
                  items: [
                    {
                      item_id: selectedPlan.id,
                      item_name: selectedPlan.name,
                    },
                  ],
                };

                trackPixelEvent(PixelEvents.Purchase, eventData);

                if (isCognifiPro) {
                  trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(eventData));
                }
              }

              if (trickFinalValue) {
                trackEvent(trickFinalValue, cfTrackHeader, userLocation).catch(console.error);
              }

              wmq.track(EvTruck.Purchase, {
                email: redirectUserEmail ?? quizData.email,
                transactionId: response.id,
                productId: selectedPlan.id ?? '',
                paymentSystem: 'recurly',
                revenue: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
                currency: 'USD',
              });

              setParamsToSessionStorage({
                tokenId: tokenId,
                iqScore: userIqScore ?? '115',
                url: paymentConfig.recurly.uri,
                subscriptionId: response.uuid,
                accountCode: response.account.code,
                attribution: JSON.stringify(
                  getAttribution({
                    country: userLocation?.country ?? '',
                    ip: userLocation?.ip ?? '',
                  })
                ),
              });

              navigate(getPathWithSearchParams(Routes.OFFERS));
            }
          })
          .catch((response) => {
            const payload: Record<string, any> = {
              email: redirectUserEmail ?? quizData.email,
              productId: selectedPlan.id ?? '',
              paymentSystem: 'recurly',
              revenue: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
              currency: 'USD',
            };

            if (typeof response.error === 'string') {
              setPaymentFormError(response.error);
              payload.error = response.error;
            }

            wmq.track(EvTruck.PurchaseError, payload);
          })
      : Promise.resolve();

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formRef.current) {
      setPaymentLoading(true);
      setSelectedPaymentMethod(PaymentMethodType.CARD);

      recurly.token(formRef.current, (err, token) => {
        if (err) {
          console.error(err);
          setPaymentFormError(err.message);
          setPaymentLoading(false);
        } else {
          setParamsToSessionStorage({
            recurlyToken: token.id,
            userEmail: quizData.email,
          });

          wmq.track([EvTruck.CardSubmit, EvTruck.ModalPaymentSubmit]);

          createSubscriptionWithCardToken(token.id).then(() => {
            setPaymentLoading(false);
          });
        }
      });
    }
  }

  const sonaraRedirectEmailParameter = getParamByKey('vUserEmail');
  const redirectVantivUserEmail =
    sonaraRedirectEmailParameter !== '' && sonaraRedirectEmailParameter !== null
      ? window.decodeURIComponent(sonaraRedirectEmailParameter)
      : null;

  function handleSonaraSubmitForm({ number, exp_month, exp_year, cvv }: CardValues) {
    setPaymentLoading(true);
    wmq.track([EvTruck.CardSubmit, EvTruck.ModalPaymentSubmit]);
    setSelectedPaymentMethod(PaymentMethodType.CARD);

    subscriptions
      .createSubscriptionNoUser({
        email: quizData.email,
        plan_id: selectedPlan.id ?? '',
        attribution: getAttribution({
          ip: userLocation?.ip ?? '',
          country: userLocation?.country ?? '',
        }),
        card: {
          number,
          exp_month,
          exp_year,
          cvv,
        },
        address: {
          first_name: quizData.firstName,
          last_name: quizData.lastName,
          country: userLocation?.country ?? undefined,
        },
      })
      .then((res) => {
        if (res.id) {
          if (!isFacebookPurchaseEventMustBePostponed) {
            const eventData = {
              email: quizData.email,
              currency: 'USD',
              value: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
              transactionId: res.id,
              items: [
                {
                  item_id: selectedPlan.id,
                  item_name: selectedPlan.name,
                },
              ],
            };

            trackPixelEvent(PixelEvents.Purchase, eventData);

            if (isCognifiPro) {
              trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(eventData));
            }
          }

          if (trickFinalValue) {
            trackEvent(trickFinalValue, cfTrackHeader, userLocation).catch(console.error);
          }

          wmq.track(EvTruck.Purchase, {
            email: quizData.email,
            transactionId: res.id,
            productId: selectedPlan.id ?? '',
            paymentSystem: 'vantiv',
            revenue: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
            currency: 'USD',
          });

          setParamsToSessionStorage({
            userEmail: quizData.email,
            subscriptionId: res.id,
            iqScore: userIqScore ?? '115',
          });

          navigate(getPathWithSearchParams(Routes.OFFERS));
        }
      })
      .catch((err) => {
        console.error('createSubscriptionNoUser err', err);
        setPaymentFormError(err.error ?? 'Something went wrong');
        setPaymentLoading(false);
      });
  }

  useUpdateEffect(() => {
    const redirectVantivSubscriptionId = getParamByKey('vSubscriptionId');
    const redirectIqScore = getParamByKey('redirectIqScore');

    if (user && redirectVantivSubscriptionId && redirectVantivUserEmail) {
      setLoading(true);
      setError(null);

      const appleToken = user.isAppleAuth ? (user.providerDataId ?? user.uid) : user.uid;

      subscriptions
        .finalizeSubscription({
          subscription_id: redirectVantivSubscriptionId,
          email: user.email,
          firebase_id: !user.isAppleAuth ? appleToken : undefined,
          apple_token: user.isAppleAuth ? appleToken : undefined,
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
        })
        .then(async (response) => {
          if (response.id) {
            let idToken = '';

            const iqScore = redirectIqScore ?? String(115);

            try {
              idToken = (await firebaseAuth.currentUser?.getIdToken(true)) ?? '';
            } catch (err) {
              console.error(err);
            }

            await saveUserData({
              user_id: user.uid,
              iq_value: iqScore,
              subscription_id: user.uid,
              name: user.displayName,
              email: user.email,
              idToken: idToken,
            }).catch((err) => {
              console.log('saveUserData err', err);
            });

            setParamsToSessionStorage({
              userEmail: '',
              subscriptionId: '',
              iqScore: '',
            });

            setUserSignedIn(true);
          }
        })
        .catch((err) => {
          console.error('finalizeSubscriptionVantiv err', err);
          setError(err.error ?? 'Finalize error');
          setLoading(false);
        });
    }
  }, [user]);

  const handleSonaraPayByApple = async () => {
    setPaymentLoading(true);
    setSelectedPaymentMethod(PaymentMethodType.APPLE);
    wmq.track([EvTruck.ApplePaySubmit, EvTruck.ModalPaymentSubmit]);

    const session = await subscriptions.initApplePaySession({
      currency: 'USD',
      amount: Number(selectedPlan.trial_sum ?? selectedPlan.sum),
      label: selectedPlan.name_full ?? '',
    });

    session.oncancel = function (event) {
      console.log('session.oncancel', event);
      setApplePayError('Something went wrong');
      setPaymentLoading(false);
    };

    session.onpaymentauthorized = function (event: any) {
      console.log('onpaymentauthorized', event);
      const token = event.payment.token.paymentData;
      const displayName = event.payment.token.paymentMethod?.displayName;
      const address = event.payment.billingContact?.addressLines ? event.payment.billingContact.addressLines[0] : null;
      const address2 = event.payment.billingContact?.addressLines ? event.payment.billingContact.addressLines[1] : null;

      subscriptions
        .createSubscriptionNoUserApplePay({
          email: quizData.email,
          plan_id: selectedPlan.id ?? '',
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
          display_name: displayName,
          token,
          address: {
            first_name: event.payment.billingContact?.givenName,
            last_name: event.payment.billingContact?.familyName,
            country: event.payment.billingContact?.countryCode,
            state: event.payment.billingContact?.administrativeArea,
            zip: event.payment.billingContact?.postalCode,
            city: event.payment.billingContact?.locality,
            ...(address && { address }),
            ...(address2 && { address2 }),
            phone: event.payment.billingContact?.phoneNumber,
          },
        })
        .then(async (response) => {
          if (response.id) {
            if (!isFacebookPurchaseEventMustBePostponed) {
              const eventData = {
                email: quizData.email,
                currency: 'USD',
                value: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
                transactionId: response.id,
                items: [
                  {
                    item_id: selectedPlan.id,
                    item_name: selectedPlan.name,
                  },
                ],
              };

              trackPixelEvent(PixelEvents.Purchase, eventData);

              if (isCognifiPro) {
                trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(eventData));
              }
            }

            if (trickFinalValue) {
              trackEvent(trickFinalValue, cfTrackHeader, userLocation).catch(console.error);
            }

            wmq.track(EvTruck.Purchase, {
              email: quizData.email,
              transactionId: response.id,
              productId: selectedPlan.id ?? '',
              paymentSystem: 'vantiv',
              revenue: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
              currency: 'USD',
            });

            session.completePayment(ApplePaySession.STATUS_SUCCESS);

            setParamsToSessionStorage({
              userEmail: quizData.email,
              subscriptionId: response.id,
              iqScore: userIqScore ?? '115',
            });

            navigate(getPathWithSearchParams(Routes.OFFERS));
          } else {
            setApplePayError('Something went wrong, cant create subscription');
          }
        })
        .catch((err) => {
          const errorMessage = err.error ?? 'Something went wrong';

          const payload: Record<string, any> = {
            email: quizData.email,
            productId: selectedPlan.id ?? '',
            paymentSystem: 'vantiv',
            revenue: selectedPlan.trial_sum ?? selectedPlan.sum ?? '',
            currency: 'USD',
            error: errorMessage,
          };

          setApplePayError(errorMessage);

          wmq.track(EvTruck.PurchaseError, payload);

          session.completePayment(ApplePaySession.STATUS_FAILURE);
        })
        .finally(() => {
          setPaymentLoading(false);
        });
    };
    session.begin();
  };

  const handleAdditionalOfferAdd = useCallback(
    (additionalPlan: PaymentPlanType, onSuccess: () => void, isSkipTrialPlan?: boolean) => {
      if (isUseSonara) {
        setAdditionalPaymentError('');
        setAdditionalPaymentLoading(true);
        const { userEmail, subscriptionId } = getParamsFromSessionStorage(['userEmail', 'subscriptionId']);

        if (userEmail && subscriptionId) {
          subscriptions
            .createSubscriptionNoUserWithSubId({
              email: userEmail,
              plan_id: additionalPlan.id,
              subscription_id: subscriptionId,
              attribution: getAttribution({
                ip: userLocation?.ip ?? '',
                country: userLocation?.country ?? '',
              }),
            })
            .then((response) => {
              if (response.id) {
                if (isCognifiPro && !isSkipTrialPlan) {
                  const eventData = {
                    email: userEmail,
                    currency: 'USD',
                    value: additionalPlan.trial_sum ?? additionalPlan.sum,
                    transactionId: response.id,
                    items: [
                      {
                        item_id: additionalPlan.id,
                        item_name: additionalPlan.name,
                      },
                    ],
                  };
                  trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(eventData));
                  trackPixelEvent(PixelEvents.OneTimePurchase, getParamsWithDecreasedValue(eventData));
                }
                trackEvent(`Purchase ${additionalPlan.name}`, cfTrackHeader, userLocation).catch(console.error);

                wmq.track(EvTruck.AdditionalPurchase, {
                  email: userEmail,
                  transactionId: response.id,
                  productId: additionalPlan?.id ?? '',
                  paymentSystem: 'vantiv',
                  revenue: additionalPlan?.trial_sum ?? additionalPlan?.sum ?? '',
                  currency: 'USD',
                });

                onSuccess();
              }
            })
            .catch((err) => {
              const errorMessage = err.error ?? 'Something went wrong';
              const payload: Record<string, any> = {
                email: userEmail,
                productId: additionalPlan.id,
                paymentSystem: 'vantiv',
                revenue: additionalPlan?.trial_sum ?? additionalPlan?.sum ?? '',
                currency: 'USD',
                error: errorMessage,
              };

              wmq.track(EvTruck.AdditionalPurchaseError, payload);

              trackEvent(`Purchase error ${additionalPlan.name}`, cfTrackHeader, userLocation).catch(console.error);

              setAdditionalPaymentError(errorMessage);
            })
            .finally(() => {
              setAdditionalPaymentLoading(false);
            });
        } else {
          setAdditionalPaymentError('No subscription data');
        }
      } else {
        setAdditionalPaymentError('');
        setAdditionalPaymentLoading(true);
        const sessionPaymentParams = getParamsFromSessionStorage(['recurlyToken', 'userEmail', 'accountCode']);

        const body = {
          cfTrackHeader,
          url: paymentConfig.recurly.uri,
          email: redirectUserEmail ?? sessionPaymentParams.userEmail,
          plan_id: additionalPlan.id,
          token_id: getParamByKey('redirectTokenId') ?? sessionPaymentParams.recurlyToken,
          account: getParamByKey('redirectAccount') ?? sessionPaymentParams.accountCode,
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
        };

        createSubscriptionNoUser(body)
          .then((response) => {
            if (isSubscribeRecurlyErrorResponse(response)) {
              setAdditionalPaymentError(response.error);
            } else if (!isSubscribeRecurlySuccessResponse(response)) {
              console.error(response);
            } else {
              if (isCognifiPro && !isSkipTrialPlan) {
                const eventData = {
                  email: redirectUserEmail ?? sessionPaymentParams.userEmail,
                  currency: 'USD',
                  value: additionalPlan.trial_sum ?? additionalPlan.sum,
                  transactionId: response.id,
                  items: [
                    {
                      item_id: additionalPlan.id,
                      item_name: additionalPlan.name,
                    },
                  ],
                };
                trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(eventData));
                trackPixelEvent(PixelEvents.OneTimePurchase, getParamsWithDecreasedValue(eventData));
              }
              trackEvent(`Purchase ${additionalPlan.name}`, cfTrackHeader, userLocation).catch(console.error);

              wmq.track(EvTruck.AdditionalPurchase, {
                email: redirectUserEmail ?? sessionPaymentParams.userEmail,
                transactionId: response.id,
                productId: additionalPlan?.id ?? '',
                paymentSystem: 'recurly',
                revenue: additionalPlan?.trial_sum ?? additionalPlan?.sum ?? '',
                currency: 'USD',
              });

              onSuccess();
            }
          })
          .catch((error) => {
            const payload: Record<string, any> = {
              email: redirectUserEmail ?? sessionPaymentParams.userEmail,
              productId: additionalPlan?.id ?? '',
              paymentSystem: 'recurly',
              revenue: additionalPlan?.trial_sum ?? additionalPlan?.sum ?? '',
              currency: 'USD',
            };

            console.error(error);

            const errorMessage = 'error' in error ? error.error : JSON.stringify(error);

            payload.error = errorMessage;

            wmq.track(EvTruck.AdditionalPurchaseError, payload);

            trackEvent(`Purchase error ${additionalPlan.name}`, cfTrackHeader, userLocation).catch(console.error);

            setAdditionalPaymentError(errorMessage);
          })
          .finally(() => {
            setAdditionalPaymentLoading(false);
          });
      }
    },
    [isUseSonara, cfTrackHeader, isCognifiPro, paymentConfig, redirectUserEmail, userLocation]
  );

  const value: PaymentContextType = {
    isReadyAppleButton,
    handleSubmitForm,
    paymentFormError,
    setPaymentFormError,
    applePayError,
    setApplePayError,
    formRef,
    isPaymentLoading,
    setPaymentLoading,
    loading,
    setLoading,
    paymentConfig,
    error,
    setError,
    isUserSignedIn,
    setUserSignedIn,
    isAdditionalPaymentLoading,
    setAdditionalPaymentLoading,
    additionalPaymentError,
    setAdditionalPaymentError,
    isUseSonara,
    cfTrackHeader,
    handleSonaraSubmitForm,
    handleSonaraPayByApple,
    isUseCardSonara,
    handleAdditionalOfferAdd,
    subscriptions,
  };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};
