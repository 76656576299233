import { trackEvent } from '@api/requests';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { useCallback, useMemo } from 'react';
import { Routes } from '../../../App';

function Access({
  setStepNumber,
  isPdfReportPurchased,
}: {
  isPdfReportPurchased: boolean;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
}) {
  const {
    isAdditionalPaymentLoading,
    additionalPaymentError,
    handleAdditionalOfferAdd,
    cfTrackHeader,
  } = usePaymentState();
  const { earlyAccessSalePlan, showAdditionalOffersPrice } = usePaymentConfig();
  const { userLocation } = useQuizState();

  const handleSkip = () => {
    trackEvent(`Skip ${earlyAccessSalePlan?.name} click`, cfTrackHeader, userLocation).catch(console.error);
    if (isPdfReportPurchased) {
      setStepNumber((prev) => prev + 2);
    } else {
      setStepNumber((prev) => prev + 1);
    }
  };

  const handleGetTheOffer = useCallback(() => {
    if (isAdditionalPaymentLoading) return;

    if (earlyAccessSalePlan) {
      handleAdditionalOfferAdd(earlyAccessSalePlan, () => {
        if (isPdfReportPurchased) {
          setStepNumber((prev) => prev + 2);
        } else {
          setStepNumber((prev) => prev + 1);
        }
      });
    }
  }, [earlyAccessSalePlan, isAdditionalPaymentLoading]);

  const points = useMemo(() => {
    return [
      {
        title: 'Advanced learning opportunities',
        image: 'svg/offer/p1.svg',
      },
      {
        title: 'Exclusive features and content',
        image: 'svg/offer/p2.svg',
      },
      {
        title: 'Be the first to play new games',
        image: 'svg/offer/p3.svg',
      },
      ...(showAdditionalOffersPrice
        ? [
            {
              title: `One-time payment $${earlyAccessSalePlan?.sum}`,
              image: 'svg/offer/p4.svg',
            },
          ]
        : []),
    ];
  }, [earlyAccessSalePlan, showAdditionalOffersPrice]);

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '20px',
      }}
    >
      <img width={128} src="images/subscription/puzzle_piece.webp" alt="decor"></img>
      <Typography
        sx={{
          margin: '20px 0 10px',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
          span: {
            color: '#6639E6',
          },
        }}
      >
        Get exclusive early access to <span>new brain games</span>
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '16px',
          lineHeight: 'normal',
          color: 'rgba(2, 2, 2, 0.50)',
          textAlign: 'center',
        }}
      >
        Access new brain games before their public release and advance your cognitive skills faster than ever!
      </Typography>
      <Box
        sx={{
          padding: '20px',
          borderRadius: ' 20px',
          background: '#F3F2F8',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          margin: '20px 0',
        }}
      >
        {points.map((el) => (
          <Box
            key={el.title}
            sx={{
              display: 'flex',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <img src={el.image} alt="point"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir500',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#020202',
              }}
            >
              {el.title}
            </Typography>
          </Box>
        ))}
      </Box>

      {additionalPaymentError && (
        <Typography
          sx={{
            margin: '10px 20px',
            color: '#E03045',
            textAlign: 'center',
          }}
        >
          {additionalPaymentError}
        </Typography>
      )}
      <QuizButton onClick={handleGetTheOffer} variant={ButtonType.MAIN} text="">
        {isAdditionalPaymentLoading ? (
          <CircularProgress size={20} style={{ color: 'white' }}></CircularProgress>
        ) : (
          <>Get early access</>
        )}
      </QuizButton>
      <Typography
        onClick={handleSkip}
        sx={{
          cursor: 'pointer',
          fontFamily: 'Avenir400',
          fontSize: '14px',
          lineHeight: 'normal',
          color: '#A9A7AF',
          textAlign: 'center',
          marginTop: '15px',
          textDecoration: 'underline',
        }}
      >
        Skip this offer and proceed further
      </Typography>

      {!showAdditionalOffersPrice && (
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            color: '#A9A7AF',
            textAlign: 'center',
            marginTop: '15px',
            '& .link': {
              color: '#A9A7AF',
              textDecorationColor: '#A9A7AF',
            },
          }}
        >
          By clicking the button, I agree to enroll in a one-time subscription at the cost of $
          {earlyAccessSalePlan?.sum}. I have thoroughly reviewed and accepted the{' '}
          <Link target="_blank" href={Routes.TERMS} className="link">
            Terms and Conditions.
          </Link>
        </Typography>
      )}
    </Box>
  );
}

export default Access;
