
import { tikTokManager } from '@services/analytics/tiktok';
import { useEffect } from 'react';


const useInitiateTikTok = () => {
    useEffect(() => {
        try {
            //Query parameter tt=...
            const tikTokPixel = new URLSearchParams(document.location.search).get('tt');

            // Init TikTok Analytics
            tikTokPixel && tikTokManager.init(tikTokPixel as string);
        } catch (e) {
            console.error('[Could not initiate tiktok pixel]: ', e);
        }
    }, []);
};

export default useInitiateTikTok;
