import { Box, Typography, useTheme } from '@mui/material';
import Checkmark from '@assets/svg/webapp/unsubscribe/updated_checkmark.svg';
import QuizButton, { ButtonType } from '@components/common/QuizButton';

function PlanUpdatedModal({ onClick }: { onClick: () => void }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#23263160',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          background: '#FFF',
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px 5px 15px 0px rgba(106, 126, 149, 0.05)',
          padding: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '500px',
          svg: {
            width: '82px',
            height: '82px',
          },
        }}
      >
        <Checkmark />

        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '20px',
            color: theme.palette.text.primary,
            marginTop: '20px',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          Your Plan Has Been Updated!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Keep exploring personalized IQ evaluations, brain-boosting games, and expert insights tailored just for you.
        </Typography>
        <QuizButton onClick={onClick} variant={ButtonType.MAIN} text={'Continue'} />
      </Box>
    </Box>
  );
}

export default PlanUpdatedModal;
