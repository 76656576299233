/**
 * App query params
 */
export enum QP {
    //---- COMMON ----
    MODE = 'mode',

    // ----UNSUNSCRIPTION ----
    UNSUNSCRIPTION = 'unsubscription',
}
