import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

import { trackEvent } from '@api/requests';
import Email from './Steps/Email';
import Intro from './Steps/Intro';
import Question from './Steps/Question';
import Analysis from './Steps/Analysis';
import { wmq } from '@services/magnus';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { QP } from '@constants/queryParams';

const getDefaultStep = (stepNumber: number) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Intro />;
    }
    case 2: {
      return <Question questionNumber={1} />;
    }
    case 3: {
      return <Question questionNumber={2} />;
    }
    case 4: {
      return <Question questionNumber={3} />;
    }
    case 5: {
      return <Question questionNumber={4} />;
    }
    case 6: {
      return <Question questionNumber={5} />;
    }
    case 7: {
      return <Question questionNumber={6} />;
    }
    case 8: {
      return <Question questionNumber={7} />;
    }
    case 9: {
      return <Question questionNumber={8} />;
    }
    case 10: {
      return <Question questionNumber={9} />;
    }
    case 11: {
      return <Question questionNumber={10} />;
    }
    case 12: {
      return <Question questionNumber={11} />;
    }
    case 13: {
      return <Question questionNumber={12} />;
    }
    case 14: {
      return <Question questionNumber={13} />;
    }
    case 15: {
      return <Question questionNumber={14} />;
    }
    case 16: {
      return <Question questionNumber={15} />;
    }
    case 17: {
      return <Question questionNumber={16} />;
    }
    case 18: {
      return <Question questionNumber={17} />;
    }
    case 19: {
      return <Question questionNumber={18} />;
    }
    case 20: {
      return <Question questionNumber={19} />;
    }
    case 21: {
      return <Question questionNumber={20} />;
    }
    case 22: {
      return <Analysis />;
    }
    case 23: {
      return <Email />;
    }
  }
};

const get5Steps = (stepNumber: number) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Intro />;
    }
    case 2: {
      return <Question questionNumber={1} stepsNumber={5} />;
    }
    case 3: {
      return <Question questionNumber={2} stepsNumber={5} />;
    }
    case 4: {
      return <Question questionNumber={3} stepsNumber={5} />;
    }
    case 5: {
      return <Question questionNumber={4} stepsNumber={5} />;
    }
    case 6: {
      return <Question questionNumber={5} stepsNumber={5} />;
    }
    case 7: {
      return <Analysis multiplier={4} />;
    }
    case 8: {
      return <Email />;
    }
  }
};

const get10Steps = (stepNumber: number) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Intro />;
    }
    case 2: {
      return <Question questionNumber={1} stepsNumber={10} />;
    }
    case 3: {
      return <Question questionNumber={2} stepsNumber={10} />;
    }
    case 4: {
      return <Question questionNumber={3} stepsNumber={10} />;
    }
    case 5: {
      return <Question questionNumber={4} stepsNumber={10} />;
    }
    case 6: {
      return <Question questionNumber={5} stepsNumber={10} />;
    }
    case 7: {
      return <Question questionNumber={6} stepsNumber={10} />;
    }
    case 8: {
      return <Question questionNumber={7} stepsNumber={10} />;
    }
    case 9: {
      return <Question questionNumber={8} stepsNumber={10} />;
    }
    case 10: {
      return <Question questionNumber={9} stepsNumber={10} />;
    }
    case 11: {
      return <Question questionNumber={10} stepsNumber={10} />;
    }
    case 12: {
      return <Analysis multiplier={2} />;
    }
    case 13: {
      return <Email />;
    }
  }
};

function Quiz() {
  const { step, userLocation } = useQuizState();
  const { cfTrackHeader } = usePaymentState();
  const mode = new URLSearchParams(window.location.search).get(QP.MODE);
  const steps = useMemo(() => {
    switch (mode) {
      case '5': {
        return get5Steps(step);
      }
      case '10': {
        return get10Steps(step);
      }
      default: {
        return getDefaultStep(step);
      }
    }
  }, [step, mode]);

  const getScreenName = (mode: string | null, step: number) => {
    switch (mode) {
      case '5': {
        if (step === 1) return 'Quiz intro screen shown';
        if (step === 7) return 'Analysis screen shown';
        if (step === 8) return 'Email screen shown';
        return `Question ${step - 1} shown`;
      }
      case '10': {
        if (step === 1) return 'Quiz intro screen shown';
        if (step === 12) return 'Analysis screen shown';
        if (step === 13) return 'Email screen shown';
        return `Question ${step - 1} shown`;
      }
      default: {
        if (step === 1) return 'Quiz intro screen shown';
        if (step === 22) return 'Analysis screen shown';
        if (step === 23) return 'Email screen shown';
        return `Question ${step - 1} shown`;
      }
    }
  };

  useEffect(() => {
    trackEvent(getScreenName(mode, step), cfTrackHeader, userLocation);
  }, [step, mode]);

  useEffect(() => {
    const getScreenName = (step: number) => {
      switch (mode) {
        case '5': {
          if (step === 1) return 'quiz_shown';
          if (step === 7) return 'analysis_shown';
          if (step === 8) return 'email_shown';
          return `question_${step - 1}_shown`;
        }
        case '10': {
          if (step === 1) return 'quiz_shown';
          if (step === 12) return 'analysis_shown';
          if (step === 13) return 'email_shown';
          return `question_${step - 1}_shown`;
        }
        default: {
          if (step === 1) return 'quiz_shown';
          if (step === 22) return 'analysis_shown';
          if (step === 23) return 'email_shown';
          return `question_${step - 1}_shown`;
        }
      }
    };

    wmq.track(getScreenName(step));
  }, [step, mode]);

  return (
    <Box
      sx={{
        minHeight: '100dvh',
        height: '100%',
      }}
    >
      {steps}
    </Box>
  );
}

export default Quiz;
