import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { Box, CircularProgress, Typography } from '@mui/material';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useMemo, useState } from 'react';
import AreYouSureModal from '../components/AreYouSureModal';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { trackEvent } from '@api/requests';
import { PixelEvents } from '@constants/events';
import { getParamsWithDecreasedValue } from '@utils/facebook';
import { trackPixelEvent } from '@services/analytics/trackEvent';

function SkipTrial({ setStepNumber }: { setStepNumber: React.Dispatch<React.SetStateAction<number>> }) {
  const {
    isAdditionalPaymentLoading,
    additionalPaymentError,
    handleAdditionalOfferAdd,
    setAdditionalPaymentError,
    cfTrackHeader,
  } = usePaymentState();
  const { selectedPlan, skipTrialPlan, showSkipPopup, isCognifiPro } = usePaymentConfig();
  const { userLocation } = useQuizState();
  const [isModalOpen, setModalOpen] = useState(false);
  const fullPlanPrice = skipTrialPlan?.sum ?? 0;
  const trialPlanPrice = selectedPlan.sum;
  const amountToSave = trialPlanPrice * 12 - fullPlanPrice;

  const handleStartTrial = () => {
    trackEvent(`Start Trial Click`, cfTrackHeader, userLocation);
    setAdditionalPaymentError('');
    if (showSkipPopup) {
      setModalOpen(true);
    } else {
      trackPixelEvent(PixelEvents.Purchase, mainPlanEventData);

      if (isCognifiPro) {
        trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(mainPlanEventData));
      }

      setStepNumber((prev) => prev + 1);
    }
  };

  const handleSkipTrial = () => {
    trackEvent(`Pay now & skip trial click`, cfTrackHeader, userLocation);
    if (skipTrialPlan) {
      handleAdditionalOfferAdd(
        skipTrialPlan,
        () => {
          trackPixelEvent(PixelEvents.Purchase, skipTrialEventData);

          if (isCognifiPro) {
            trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(skipTrialEventData));
          }

          setStepNumber((prev) => prev + 1);
        },
        true
      );
    }
  };

  const handleAcceptThisOffer = () => {
    if (skipTrialPlan) {
      trackEvent(`Accept This Offer Click`, cfTrackHeader, userLocation);
      handleAdditionalOfferAdd(
        skipTrialPlan,
        () => {
          trackPixelEvent(PixelEvents.Purchase, skipTrialEventData);

          if (isCognifiPro) {
            trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(skipTrialEventData));
          }

          setStepNumber((prev) => prev + 1);
        },
        true
      );
    }
  };
  const mainPlanSum = selectedPlan.trial_sum ?? selectedPlan.sum;
  const skipTrialPlanSum = skipTrialPlan?.trial_sum ?? skipTrialPlan?.sum ?? 0;
  const skipTrialPlanFullSum = mainPlanSum + skipTrialPlanSum;

  const skipTrialEventData = useMemo(() => {
    return {
      currency: 'USD',
      value: skipTrialPlanFullSum,
      items: [
        {
          item_id: skipTrialPlan?.id,
          item_name: skipTrialPlan?.name,
        },
      ],
    };
  }, [skipTrialPlan, skipTrialPlanFullSum]);

  const mainPlanEventData = useMemo(() => {
    return {
      currency: 'USD',
      value: mainPlanSum,
      items: [
        {
          item_id: selectedPlan.id,
          item_name: selectedPlan.name,
        },
      ],
    };
  }, [selectedPlan, mainPlanSum]);

  const handleSkipThisOffer = () => {
    trackPixelEvent(PixelEvents.Purchase, mainPlanEventData);
    if (isCognifiPro) {
      trackPixelEvent(PixelEvents.AllPurchase, getParamsWithDecreasedValue(mainPlanEventData));
    }
    trackEvent(`Skip This Offer Modal Click`, cfTrackHeader, userLocation);
    setAdditionalPaymentError('');
    setModalOpen(false);
    setStepNumber((prev) => prev + 1);
  };

  return (
    <Box
      sx={{
        maxWidth: '460px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        margin: 'auto',
        padding: '0 20px',
      }}
    >
      <Typography
        sx={{
          margin: '20px 0 0',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#020202',
          textAlign: 'center',
        }}
      >
        Not intending to look back?
      </Typography>

      <Box
        sx={{
          paddingTop: '30px',
          marginTop: '20px',
          display: 'flex',
          width: '100%',
          gap: '11px',
          '& .wrapper': {
            borderRadius: '20px',
            border: '1px solid rgba(2, 2, 2, 0.10)',
            padding: '20px 10px 10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          },
          '& .title': {
            fontFamily: 'Avenir600',
            fontSize: '14px',
            lineHeight: 'normal',
            color: '#020202',
            textAlign: 'center',
          },
          '& .subTitle': {
            fontFamily: 'Avenir400',
            fontSize: '12px',
            lineHeight: 'normal',
            color: '#A9A7AF',
            textAlign: 'center',
          },
        }}
      >
        <Box className="wrapper">
          <Typography className="title">${trialPlanPrice}/week</Typography>
          <Box>
            <Typography className="subTitle">Billing period</Typography>
            <Typography className="title">Every week</Typography>
          </Box>
          <Box>
            <Typography className="subTitle">Billed amount</Typography>
            <Typography className="title">${trialPlanPrice}</Typography>
          </Box>
          <Box>
            <Typography className="subTitle">Billed in 12 weeks</Typography>
            <Typography className="title">${trialPlanPrice * 12}</Typography>
          </Box>
          <QuizButton onClick={handleStartTrial} variant={ButtonType.WHITE} text={'Start trial'} />
        </Box>
        <Box
          className="wrapper"
          sx={{
            position: 'relative',
            background: '#fff',
            border: '1px solid #6639E6!important',
            transformStyle: 'preserve-3d',
            '&::after': {
              transform: 'translateZ(-1px)',
              content: '""',
              display: 'block',
              height: 80,
              width: 'calc(100% + 2px)',
              borderRadius: '20px',
              background: 'linear-gradient(90deg, #FF84D5 1.23%, #AF5FFF 50.62%, #6639E6 100%)',
              position: 'absolute',
              top: '-30px',
              left: '-1px',
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '12px',
              lineHeight: 'normal',
              color: '#fff',
              textAlign: 'center',
              width: '100%',
              position: 'absolute',
              top: '-22px',
              left: 0,
            }}
          >
            SAVE 80%
          </Typography>
          <Typography className="title">${(fullPlanPrice / 12).toFixed(2)}/week</Typography>
          <Box>
            <Typography className="subTitle">Billing period</Typography>
            <Typography className="title">Every 12 weeks</Typography>
          </Box>
          <Box>
            <Typography className="subTitle">Billed amount</Typography>
            <Typography className="title">${fullPlanPrice}</Typography>
          </Box>
          <Box>
            <Typography className="subTitle">Billed in 12 weeks</Typography>
            <Typography className="title">${fullPlanPrice}</Typography>
          </Box>
          <QuizButton onClick={handleSkipTrial} variant={ButtonType.SKIP_TRIAL} text="">
            {isAdditionalPaymentLoading ? (
              <CircularProgress size={20} style={{ color: 'white' }}></CircularProgress>
            ) : (
              <>Pay now & skip trial</>
            )}
          </QuizButton>
        </Box>
      </Box>
      {additionalPaymentError && (
        <Typography
          sx={{
            margin: '20px 0 0 0',
            color: '#E03045',
            textAlign: 'center',
          }}
        >
          {additionalPaymentError}
        </Typography>
      )}

      <AreYouSureModal
        isModalOpen={isModalOpen}
        amountToSave={amountToSave}
        onGetClick={handleAcceptThisOffer}
        onSkipClick={handleSkipThisOffer}
        isLoading={isAdditionalPaymentLoading}
        btnText="Accept this offer"
        subTitle={
          <>
            Start our 12-week journey just for <span className="black">${fullPlanPrice}</span> and{' '}
            <span className="color">save ${amountToSave}</span> by skipping the trial.
          </>
        }
        points={[
          <>Get your precise IQ score with our scientifically-validated assessment</>,
          <>Know where you stand compared</>,
          <>
            Discover your cognitive <span>strengths</span> and <span>areas for improvement</span>
          </>,
          <>
            Boost your IQ by <span>up to 37% in 4 weeks</span> with personalized training.
          </>,
        ]}
      />
    </Box>
  );
}

export default SkipTrial;
