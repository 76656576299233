import { useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import P10 from '@assets/svg/webapp/unsubscribe/p10.svg';
import P11 from '@assets/svg/webapp/unsubscribe/p11.svg';
import P12 from '@assets/svg/webapp/unsubscribe/p12.svg';
import StepHeader from '../components/StepHeader';
import { useWebAppState } from '@components/WebApp/WebApp';
import PlanUpdatedModal from '../components/PlanUpdatedModal';
import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { firebaseAuth } from '@services/firebase';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import {
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  GIFT_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
} from '@constants/subscriptions';
import { createSubscriptionWithFirebaseId } from '@api/requests';

function Step8({
  checkUserSubscriptions,
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  checkUserSubscriptions: () => void;
}) {
  const [isUpdated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { closeUnsubscribe, userSubscription } = useWebAppState();
  const { subscriptions, cfTrackHeader } = usePaymentState();
  const { paymentConfig } = usePaymentConfig();

  const theme = useTheme();
  const points = [
    {
      title: 'Advanced IQ challenges to push your limits',
      img: <P10 />,
    },
    {
      title: 'New brain-boosting games for sharper thinking',
      img: <P11 />,
    },
    {
      title: 'Deeper insights into your cognitive growth',
      img: <P12 />,
    },
  ];

  const handleChangePlan = () => {
    const user = firebaseAuth.currentUser;

    if (loading || !user) return;

    const isAppleAuth = user.providerData[0]?.providerId === 'apple.com';
    const userUid = isAppleAuth ? user.providerData[0].uid : user.uid;
    const isSonara = userSubscription?.id.includes('-');
    const isCognifiProSubscription = userSubscription?.plan_code.includes('_pro_');
    const planId = isCognifiProSubscription
      ? GIFT_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID
      : GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID;

    const request = isSonara
      ? subscriptions.createSubscription({
          plan_id: planId,
          email: user.email ?? '',
          apple_token: isAppleAuth ? userUid : undefined,
          firebase_id: !isAppleAuth ? userUid : undefined,
        })
      : createSubscriptionWithFirebaseId({
          cfTrackHeader,
          url: paymentConfig.recurly.uri,
          plan_id: planId,
          email: user.email ?? '',
          apple_token: isAppleAuth ? userUid : undefined,
          firebase_id: !isAppleAuth ? userUid : undefined,
        });

    request
      .then(async (response) => {
        if ('error' in response) {
          setError(response.error);
        }
        if ('id' in response) {
          setUpdated(true);
          checkUserSubscriptions();
        }
      })
      .catch(async (error) => {
        const errorMessage = error?.error;
        setError(errorMessage ?? "Something went wrong, please contact support");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        backgroundImage: 'url(/cognifi/images/gradient_background.webp)',
        backgroundSize: '100% 400px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="One last thing"
      />

      <Box
        sx={{
          padding: '20px 20px 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',

            img: {
              width: '100%',
            },
          }}
        >
          <img src={'/cognifi/images/unsubscribe/offer.webp'} alt="gift"></img>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '5px',
          }}
        >
          Exciting new features are coming soon:
        </Typography>
        <Box
          sx={{
            padding: '20px',
            borderRadius: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            margin: '20px 0',
            background: '#F3F2F8',
          }}
        >
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  svg: {
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                {el.img}
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  lineHeight: 'normal',
                  fontSize: '14px',
                  color: theme.palette.text.primary,
                }}
              >
                {el.title}
              </Typography>
            </Box>
          ))}
        </Box>
        {error !== '' && (
          <Box
            sx={{
              textAlign: 'center',
              width: '100%',
              paddingBottom: '15px',
              span: {
                color: '#E53E3E',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 'normal',
              },
            }}
          >
            <span>{error}</span>
          </Box>
        )}

        <QuizButton onClick={handleChangePlan} variant={ButtonType.MAIN} text={''}>
          {loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            <>Get my free month</>
          )}
        </QuizButton>
        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          I don't want to be smarter
        </Typography>
      </Box>

      {isUpdated && (
        <PlanUpdatedModal
          onClick={() => {
            closeUnsubscribe();
          }}
        />
      )}
    </Box>
  );
}

export default Step8;
