import { useCallback } from 'react';
import { UnsubsAnswersType } from '../UnsubscribeModal';
import { Box, Typography, useTheme } from '@mui/material';
import Star from '@assets/svg/webapp/unsubscribe/star.svg';
import StarPath from '@assets/svg/webapp/unsubscribe/star_path.svg';
import StepHeader from '../components/StepHeader';
import QuizButton, { ButtonType } from '@components/common/QuizButton';

function Step7({
  setStep,
  unsubAnswers,
  setUnsubAnswers,
  isHasGiftOneMonthSubscription,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  unsubAnswers: UnsubsAnswersType;
  setUnsubAnswers: React.Dispatch<React.SetStateAction<UnsubsAnswersType>>;
  isHasGiftOneMonthSubscription: boolean;
}) {
  const theme = useTheme();

  const handleContinue = useCallback(() => {
    if (isHasGiftOneMonthSubscription) {
      setStep((prev) => prev + 2);
    } else {
      setStep((prev) => prev + 1);
    }
  }, [setStep, isHasGiftOneMonthSubscription]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Your voice matters"
      />
      <Box
        sx={{
          flexGrow: 1,
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          Let us know if there’s anything we can do to make Cognify work better for you.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
          }}
        >
          {[1, 2, 3, 4, 5].map((el) => (
            <Box
              key={el}
              onClick={() => {
                setUnsubAnswers((prev) => ({ ...prev, stars: el }));
              }}
              sx={{
                svg: {
                  width: '30px',
                  height: '28px',
                },
              }}
            >
              {el - 1 < (unsubAnswers.stars ?? 0) ? <Star /> : <StarPath />}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 0',
            '& textarea': {
              width: '100%',
              height: '100px',
              borderRadius: '12px',
              padding: '15px',
              color: theme.palette.text.primary,
              fontFamily: 'Avenir400',
              fontWeight: 400,
              fontSize: '16px',
              border: '1px solid #6639E6',
              caretColor: '#6639E6',
              '&:focus-visible': {
                outline: 'none',
                border: '1px solid #6639E6',
              },
            },
            '& textarea::placeholder': {
              color: 'rgba(35, 38, 49, 0.20)',
              fontFamily: 'Avenir400',
              fontWeight: 400,
              fontSize: '16px',
            },
          }}
        >
          <textarea
            autoFocus
            rows={5}
            placeholder="Write your review"
            value={unsubAnswers.feedback}
            onChange={(e) => {
              setUnsubAnswers((prev) => ({ ...prev, feedback: e.target.value }));
            }}
          ></textarea>
        </Box>
        <QuizButton
          disabled={unsubAnswers.feedback === ''}
          onClick={handleContinue}
          variant={ButtonType.MAIN}
          text={'Continue'}
        />
      </Box>
    </Box>
  );
}

export default Step7;
