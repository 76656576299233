import { Box, Typography, useTheme } from '@mui/material';
import Checkmark from '@assets/svg/webapp/unsubscribe/checkmark.svg';
import Questionmark from '@assets/svg/webapp/unsubscribe/questionmark.svg';
import P6 from '@assets/svg/webapp/unsubscribe/p6.svg';
import P7 from '@assets/svg/webapp/unsubscribe/p7.svg';
import P8 from '@assets/svg/webapp/unsubscribe/p8.svg';
import P9 from '@assets/svg/webapp/unsubscribe/p9.svg';
import Decor from '@assets/svg/webapp/unsubscribe/decor_palm.svg';
import * as animation from '@assets/json/JSON_Cognifi-Logo-Loop.json';

import StepHeader from '../components/StepHeader';
import { ISubscription } from '@api/requests';
import { getSubscriptionInfo } from '@utils/common';
import Lottie from 'react-lottie-player';
import QuizButton, { ButtonType } from '@components/common/QuizButton';

function Step3({
  setStep,
  userSubscription,
  isHasPremiumOneMonthSubscription,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  userSubscription: ISubscription | null;
  isHasPremiumOneMonthSubscription: boolean;
}) {
  const theme = useTheme();

  const points = [
    {
      title: 'Your true potential',
      subTitle: 'Get a real insights into your cognitive strengths',
    },
    {
      title: 'Smart games to boost IQ',
      subTitle: 'Scientifically designed for a sharp mind',
    },
    {
      title: 'Strategies to think smarter',
      subTitle: 'Expert insights at your fingertips',
    },
    {
      title: 'Ongoing Mental Growth',
      subTitle: 'Memory, problem-solving, and critical thinking',
    },
    {
      title: 'Improve & learn every day',
      subTitle: 'Even without connection',
    },
  ];

  const userPlan = getSubscriptionInfo(userSubscription?.plan_code);
  const planInfo = [
    {
      title: 'Status',
      value: userPlan.status,
      img: <P6 />,
    },
    {
      title: 'Billing period',
      value: userPlan.billingPeriod,
      img: <P7 />,
    },
    {
      title: 'Price',
      value: `$${userPlan.price}`,
      img: <P8 />,
    },
    {
      title: 'Extras to unlock',
      value: '3 extras',
      img: <P9 />,
    },
  ];

  const handleChangePlanClick = () => {
    if (isHasPremiumOneMonthSubscription) {
      setStep((prev) => prev + 2);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <Box
      sx={{
        background: '#F3F2F8',
      }}
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 2);
        }}
        title="Your Membership"
      />
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          play
          animationData={animation}
          style={{
            width: '70px',
            height: '52px',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          Current subscription plan
        </Typography>
        <Box
          sx={{
            background: '#fff',
            padding: '15px 20px',
            borderRadius: '24px',
            width: '100%',
          }}
        >
          {planInfo.map((el, i, arr) => (
            <Box key={i}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '15px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    svg: {
                      width: '24px',
                      height: '24px',
                    },
                  }}
                >
                  {el.img}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '14px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      fontSize: '16px',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {el.value}
                  </Typography>
                </Box>
              </Box>
              {i < arr.length - 1 && (
                <Box
                  sx={{
                    height: '1px',
                    background: '#232631',
                    opacity: 0.1,
                    margin: '10px 0 10px 39px',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ background: '#fff', padding: '40px 20px 0' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Box>
            <Decor />
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              lineHeight: 'normal',
              fontSize: '24px',
              color: theme.palette.text.primary,
              textAlign: 'center',
              span: {
                color: 'var(--primary-color)',
              },
            }}
          >
            What's included in your <span>premium membership?</span>
          </Typography>
          <Box
            sx={{
              transform: 'scaleX(-1)',
            }}
          >
            <Decor />
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: '#E53E3E',
            marginTop: '20px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Don’t lose these game-changing insights to level up your brain!
        </Typography>
        <Box
          sx={{
            background: '#F3F2F8',
            borderRadius: '20px',
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          {points.map((el, i) => (
            <Box key={i}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      fontSize: '16px',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '14px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {el.subTitle}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    svg: {
                      width: '32px',
                      height: '32px',
                    },
                  }}
                >
                  <Checkmark />
                </Box>
              </Box>
              <Box
                sx={{
                  height: '1px',
                  background: 'rgba(141, 104, 71, 0.20)',
                  margin: '15px 0',
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: '16px',
                color: '#E53E3E',
                span: {
                  fontWeight: 600,
                },
              }}
            >
              Are you sure you want to <span>lose all</span> this premium features?
            </Typography>
            <Box
              sx={{
                svg: {
                  width: '32px',
                  height: '32px',
                },
              }}
            >
              <Questionmark />
            </Box>
          </Box>
        </Box>
        <QuizButton onClick={handleChangePlanClick} variant={ButtonType.MAIN} text={'Change my plan'} />
        <Typography
          onClick={() => {
            setStep((prev) => prev + 2);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir400',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            paddingBottom: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          Lose access to all features
        </Typography>
      </Box>
    </Box>
  );
}

export default Step3;
