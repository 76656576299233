import { getParamsFromSessionStorage } from '@utils/storage'
import { LocationConfig } from '../../api/requests'
import { getParamByKey } from '@utils/common'

export enum PaymentMethodType {
    APPLE = 'apple',
    CARD = 'card',
}

export type QuizData = {
    startDate: number | null
    quizAnswers: Record<number, string | null>
    email: string
    isPaymentModalOpen: boolean
    firstName: string
    lastName: string
}

const { userQuizEmail } = getParamsFromSessionStorage(['userQuizEmail'])
const redirectUserEmail = getParamByKey('redirectUserEmail')

export const DEFAUL_QUIZ_DATA: QuizData = {
    startDate: null,
    email: userQuizEmail ?? redirectUserEmail ?? '',
    isPaymentModalOpen: false,
    firstName: '',
    lastName: '',
    quizAnswers: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
        11: null,
        12: null,
        13: null,
        14: null,
        15: null,
        16: null,
        17: null,
        18: null,
        19: null,
        20: null,
    },
}

export type QuizContextType = {
    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    quizData: QuizData
    setQuizData: React.Dispatch<React.SetStateAction<QuizData>>
    userLocation: LocationConfig | null
    userIqScore: string | null
    setUserIqScore: (value: string | null) => void
}
