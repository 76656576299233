import { useState } from 'react'
import { Box, Link, Typography } from '@mui/material'
import QuizContainer from '../../common/QuizContainer'
import QuizButton, { ButtonType } from '../../common/QuizButton'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper'
import { Routes } from '../../../App'
import { EMAIL_REGEX } from '@utils/constants'
import { useNavigate } from 'react-router-dom'
import { getPathWithSearchParams } from '@utils/ulr'
import { wmq } from '@services/magnus'
import { EvTruck } from '@models/events'
import { setParamsToSessionStorage } from '@utils/storage'

function Email() {
    const navigate = useNavigate()
    const { quizData, setQuizData } = useQuizState()
    const [error, setError] = useState(false)

    const handleContinue = () => {
        const isValid = quizData.email.toLowerCase().match(EMAIL_REGEX)

        if (isValid) {
            setParamsToSessionStorage({
                userQuizEmail: quizData.email,
            })
            wmq.track(EvTruck.EmailSuccess)
            navigate(getPathWithSearchParams(Routes.SUBSCRIPTION))
        } else {
            setError(true)
        }
    }

    return (
        <QuizStepBackgroundWrapper>
            <QuizContainer>
                <Box
                    sx={{
                        marginTop: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        width={'70px'}
                        height={'52px'}
                        src={'svg/intro_sign.svg'}
                        alt="logo"
                    ></img>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir600',
                            fontWeight: 600,
                            fontSize: '24px',
                            userSelect: 'none',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            color: '#020202',
                            marginTop: '20px',
                            marginBottom: '10px',
                        }}
                    >
                        Discover Your IQ Potenital!
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            userSelect: 'none',
                            textAlign: 'center',
                            lineHeight: 'normal',
                            color: 'rgba(2, 2, 2, 0.50)',
                        }}
                    >
                        Submit your email to unlock exclusive access to our
                        in-depth IQ assessment. Discover your intellectual
                        strengths and weaknesses, and start your path to
                        cognitive improvement.
                    </Typography>

                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            margin: '20px 0',
                            '& img': {
                                position: 'absolute',
                                top: '20px',
                                left: '20px',
                            },
                            '& input': {
                                boxSizing: 'border-box',
                                width: '100%',
                                background: 'white',
                                border: '1px solid var(--primary-color)',
                                borderRadius: '12px',
                                color: 'rgba(40, 38, 36, 1)',
                                fontFamily: 'Avenir500',
                                fontSize: '16px',
                                height: '60px',
                                paddingLeft: '60px',

                                '&:focus-visible': {
                                    outline: 'none',
                                },
                            },
                            '& input::placeholder': {
                                color: 'rgba(144, 140, 137, 1)',
                                fontFamily: 'Avenir400',
                                fontSize: '16px',
                                lineHeight: '22px',
                            },
                        }}
                    >
                        <img src={'svg/mail.svg'} alt="mail"></img>
                        <input
                            placeholder="Enter your email"
                            type="email"
                            value={quizData.email}
                            onChange={(e) => {
                                setError(false)
                                setQuizData((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }))
                            }}
                        ></input>
                        {error && (
                            <Typography
                                sx={{
                                    color: '#E03045',
                                    fontFamily: 'Avenir400',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                }}
                            >
                                Please enter a valid email
                            </Typography>
                        )}
                    </Box>

                    <Box
                        style={{
                            width: '100%',
                            marginBottom: '20px',
                        }}
                    >
                        <QuizButton
                            onClick={handleContinue}
                            variant={ButtonType.MAIN}
                            text="Continue"
                        ></QuizButton>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            borderTop: '1px solid #E6E7EB',
                            padding: '20px 0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Avenir400',
                                fontSize: '12px',
                                color: 'rgba(2, 2, 2, 0.50)',
                                textAlign: 'center',
                                userSelect: 'none',
                                lineHeight: 'normal',
                                a: {
                                    fontFamily: 'Avenir600',
                                    color: 'var(--primary-color)',
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            Cognifi guarantees the privacy of your personal
                            data. By clicking “Continue” you confirm that you've
                            read, understood, and agree to Cognifi's{' '}
                            <Link
                                target="_blank"
                                href={Routes.TERMS}
                                className="link"
                            >
                                Terms & Conditions
                            </Link>{' '}
                            and{' '}
                            <Link
                                target="_blank"
                                href={Routes.PRIVACY}
                                className="link"
                            >
                                Privacy Policy
                            </Link>
                            .
                        </Typography>
                    </Box>
                </Box>
            </QuizContainer>
        </QuizStepBackgroundWrapper>
    )
}

export default Email
