/**
 * Sonara Subscriptions JavaScript SDK
 * Version 1.0.0
 */

class SonaraSubscriptions {
    /**
     * Initialize the Sonara Subscriptions client
     * @param {Object} config Configuration object
     * @param {string} config.environment 'production' or 'staging'
     * @param {string} [config.customDomain] Optional custom domain
     */
    constructor(config) {
        this.environment = config.environment || 'production';
        this.customDomain = config.customDomain;
        this.baseUrl = this._getBaseUrl();
        this.defaultHeaders = {
            'Content-Type': 'application/json',
        };
    }

    /**
     * Get base URL based on environment
     * @private
     */
    _getBaseUrl() {
        if (this.customDomain) {
            return this.customDomain;
        }

        return this.environment === 'production' ? 'https://subtrack.appycnt.com' : 'https://subtrack-stg.appycnt.com';
    }

    /**
     * Make API request
     * @private
     */
    async _request(endpoint, method = 'GET', data = null, url = this.baseUrl) {
        const options = {
            method,
            headers: this.defaultHeaders,
        };

        if (data) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(`${url}${endpoint}`, options);
        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'An error occurred');
        }

        return responseData;
    }

    /**
     * Create subscription without user (card payment)
     * @param {Object} params Subscription parameters
     * @param {string} params.plan_id Plan ID
     * @param {string} params.email Customer email
     * @param {Object} [params.attribution] Attribution data
     * @param {Object} [params.card] Card details
     * @param {Object} [params.address] Billing address
     */
    async createSubscriptionNoUser(params) {
        return this._request('/subs/create-no-user', 'POST', {
            plan_id: params.plan_id,
            email: params.email,
            attribution: params.attribution,
            card: params.card,
            address: params.address,
        });
    }

    /**
     * Create subscription by main sub id without user
     * @param {Object} params Subscription parameters
     * @param {string} params.plan_id Plan ID
     * @param {string} params.email Customer email
     * @param {string} params.subscription_id Main plan subscriptoin id
     * @param {Object} [params.attribution] Attribution data
     * @param {Object} [params.address] Billing address
     */
    async createSubscriptionNoUserWithSubId(params) {
        return this._request('/subs/create-no-user', 'POST', {
            plan_id: params.plan_id,
            email: params.email,
            subscription_id: params.subscription_id,
            attribution: params.attribution,
        });
    }

    /**
     * Create subscription without user (Apple Pay)
     * @param {Object} params Subscription parameters
     * @param {string} params.plan_id Plan ID
     * @param {string} params.email Customer email
     * @param {Object} params.token Apple Pay token
     * @param {Object} [params.attribution] Attribution data
     * @param {Object} [params.address] Billing address
     * @param {string} params.display_name Display name
     */
    async createSubscriptionNoUserApplePay(params) {
        return this._request('/subs/create-no-user-applepay', 'POST', {
            plan_id: params.plan_id,
            email: params.email,
            token: params.token,
            attribution: params.attribution,
            address: params.address,
            display_name: params.display_name
        });
    }

    /**
     * Create subscription with user
     * @param {Object} params Subscription parameters
     * @param {string} params.plan_id Plan ID
     * @param {string} params.email Customer email
     * @param {string} [params.firebase_id] Firebase user ID
     * @param {string} [params.apple_token] Apple authentication token
     * @param {Object} [params.card] Card details (optional if using saved payment method)
     * @param {Object} [params.attribution] Attribution data
     */
    async createSubscription(params) {
        return this._request('/subs/create', 'POST', {
            plan_id: params.plan_id,
            email: params.email,
            firebase_id: params.firebase_id,
            apple_token: params.apple_token,
            card: params.card,
            attribution: params.attribution,
        });
    }

    /**
     * Create subscription with Apple Pay
     * @param {Object} params Subscription parameters
     * @param {string} params.plan_id Plan ID
     * @param {string} params.email Customer email
     * @param {string} params.firebase_id Firebase user ID
     * @param {Object} params.token Apple Pay token
     * @param {Object} [params.attribution] Attribution data
     * @param {Object} [params.address] Billing address
     */
    async createSubscriptionApplePay(params) {
        return this._request('/subs/create-applepay', 'POST', {
            plan_id: params.plan_id,
            email: params.email,
            firebase_id: params.firebase_id,
            token: params.token,
            attribution: params.attribution,
            address: params.address,
        });
    }

    /**
     * Finalize (activate) subscription
     * @param {Object} params Finalization parameters
     * @param {string} params.subscription_id Subscription ID
     * @param {string} params.email Customer email
     * @param {string | undefined} params.firebase_id Firebase user ID
     * @param {string | undefined} params.apple_token Apple authentication token
     * @param {Object} [params.attribution] Attribution data
     */
    async finalizeSubscription(params) {
        return this._request('/subs/finalize', 'POST', {
            subscription_id: params.subscription_id,
            email: params.email,
            firebase_id: params.firebase_id,
            apple_token: params.apple_token,
            attribution: params.attribution,
        });
    }

    /**
     * Get subscriptions
     * @param {string} id Subscription ID or sign-in token
     */
    async getSubscriptions(id) {
        return this._request(`/rec/subscriptions/${id}`, 'POST');
    }

    /**
     * Initialize Apple Pay session
     * @param {Object} params Payment parameters
     * @param {number} params.amount Payment amount
     * @param {string} params.label Payment label
     * @param {string} params.currency Currency code (e.g. 'USD')
     * @returns {Promise<ApplePaySession>}
     */
    async initApplePaySession(params) {
        if (!window.ApplePaySession) {
            throw new Error('Apple Pay is not available');
        }

        const request = {
            countryCode: 'US',
            currencyCode: params.currency,
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: {
                label: params.label,
                amount: params.amount.toString(),
            },
            requiredBillingContactFields: ['email', 'name', 'phone', 'postalAddress']
        };

        const session = new window.ApplePaySession(3, request);

        session.onvalidatemerchant = async (event) => {
            try {
                const merchantSession = await this._request(
                    '/api/apple-pay/validate-merchant',
                    'POST',
                    {
                        ...event,
                        domain: window.location.hostname,
                        validationURL: event.validationURL,
                    },
                    window.location.origin,
                );
                session.completeMerchantValidation(merchantSession);
            } catch (error) {
                console.error('Error validating merchant:', error);
                session.abort();
            }
        };

        session.onpaymentauthorized = async (event) => {
            try {
                // Return the payment token for further processing
                return event.payment.token.paymentData;
            } catch (error) {
                console.error('Error processing payment:', error);
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            }
        };

        return session;
    }

    /**
     * Validate card number using Luhn algorithm
     * @param {string} cardNumber Card number to validate
     * @returns {boolean}
     */
    validateCard(cardNumber) {
        const digits = cardNumber.replace(/\D/g, '');
        let sum = 0;
        let isEven = false;

        for (let i = digits.length - 1; i >= 0; i--) {
            let digit = parseInt(digits[i], 10);

            if (isEven) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            isEven = !isEven;
        }

        return sum % 10 === 0;
    }

    /**
     * Format card number with spaces
     * @param {string} cardNumber Raw card number
     * @returns {string} Formatted card number
     */
    formatCardNumber(cardNumber) {
        const digits = cardNumber.replace(/\D/g, '');
        const groups = digits.match(/.{1,4}/g) || [];
        return groups.join(' ');
    }

    /**
     * Detect card type based on number
     * @param {string} cardNumber Card number
     * @returns {string} Card type (visa, mastercard, amex, discover, unknown)
     */
    detectCardType(cardNumber) {
        const patterns = {
            visa: /^4/,
            mastercard: /^5[1-5]/,
            amex: /^3[47]/,
            discover: /^6(?:011|5)/,
        };

        for (const [type, pattern] of Object.entries(patterns)) {
            if (pattern.test(cardNumber)) {
                return type;
            }
        }

        return 'unknown';
    }
}

// // Export for different module systems
// if (typeof module !== 'undefined' && module.exports) {
//     module.exports = SonaraSubscriptions;
// } else if (typeof define === 'function' && define.amd) {
//     define([], function() { return SonaraSubscriptions; });
// } else {
//     window.SonaraSubscriptions = SonaraSubscriptions;
// }

export default SonaraSubscriptions;
