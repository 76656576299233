import { IUser, useUser } from '@providers/UserProvider';
import { firebaseAuth } from '@services/firebase';
import { getRedirectResult } from 'firebase/auth';
import { useLayoutEffect } from 'react';

export function useCheckAuth() {
  const { setUser } = useUser();

  useLayoutEffect(() => {
    getRedirectResult(firebaseAuth)
      .then((response) => {
        console.log('getRedirectResult',{response})
        if (response?.user) {
          const providerData = response.user.providerData.find(
            (provider) => provider.providerId === response.providerId
          );

          const user = {
            uid: response.user.uid,
            email: response.user.email ?? '',
            displayName: response.user.displayName ?? '',
            providerId: providerData?.providerId as IUser['providerId'],
            providerDataId: providerData?.uid as IUser['providerDataId'],
            isAppleAuth: providerData?.providerId === 'apple.com',
            isGoogleAuth: providerData?.providerId === 'google.com',
          };
          setUser(user);
        }
      })
      .catch(console.error);
  }, []);
}
