// PREMIUM PURCHASE ON UNSUBSCRIBE
export const PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID = 'cognifi_premium_one_month';
export const PREMIUM_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID = 'cognifi_pro_premium_one_month';
export const PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS = [
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
];

// GIFT ONE MONTH PURCHASE ON UNSUBSCRIBE
export const GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID = 'cognifi_gift_one_month';
export const GIFT_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID = 'cognifi_pro_gift_one_month';
export const GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS = [
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  GIFT_PRO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
];

// 5 hours in milliseconds
export const LOGOUT_TIMEOUT = 5 * 60 * 60 * 1000;

const tenHours = 10 * 60 * 60 * 1000;
const tenMinutes = 10 * 60 * 1000;

export const FETCHING_TIMEOUT =
  process.env.NODE_ENV === 'development' ||
  window.location.host.includes('dev.') ||
  window.location.host.includes('stg.')
    ? tenMinutes
    : tenHours;
