import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import QuizButton, { ButtonType } from '../../common/QuizButton';

function Header() {
  const [isShowButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 1000);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          height: '112px',
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
          padding: '40px 20px 20px',
          position: 'fixed',
          zIndex: 999,
          bottom: isShowButton ? 0 : -112,
          transition: 'all 0.5s ease',
          width: '100%',
          maxWidth: '460px',
          display: 'flex',
        }}
      >
        <QuizButton
          height="52px"
          onClick={() => {
            const paymentBlock = document.getElementById('payment_block');
            paymentBlock?.scrollIntoView({ behavior: 'smooth' });
          }}
          variant={ButtonType.MAIN}
          text="Get My IQ Certificate Now!"
        ></QuizButton>
      </Box>
      <Box
        sx={{
          maxWidth: '460px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            margin: '17px auto',
          }}
        >
          <img width={'108px'} height={'26px'} src={'svg/logo.svg'} alt="logo"></img>
        </Box>
        <Box
          sx={{
            padding: '15px 20px',
            background: '#6639E6',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir400',
              fontSize: '14px',
              color: '#fff',
              userSelect: 'none',
              textAlign: 'center',
              span: {
                fontFamily: 'Avenir600',
              },
            }}
          >
            Special Welcome Offer! <span>Get 85% Discount</span> Today!
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'relative',
            img: {
              maxWidth: '100%',
            },
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              fontFamily: 'Avenir600',
              fontSize: '24px',
              color: '#000',
              userSelect: 'none',
              textAlign: 'center',
              margin: '20px',
              lineHeight: 'normal',
              span: {
                color: '#6639E6',
              },
            }}
          >
            <span>Find out</span> how you measure up against others
          </Typography>
          <img draggable={false} src="images/subscription_image.webp" alt="results"></img>

          <Box
            sx={{
              padding: '20px 20px 30px',
              position: 'absolute',
              width: '100%',
              bottom: 0,
            }}
          >
            <QuizButton
              onClick={() => {
                const paymentBlock = document.getElementById('payment_block');
                paymentBlock?.scrollIntoView({ behavior: 'smooth' });
              }}
              variant={ButtonType.MAIN}
              text="Get My IQ Certificate Now!"
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '24px',
            color: '#000',
            userSelect: 'none',
            textAlign: 'center',
            margin: '40px 20px 15px',
            lineHeight: 'normal',
            span: {
              color: '#6639E6',
            },
          }}
        >
          Try <span>Cognifi</span> for 3 days
        </Typography>
        <Box sx={{}}></Box>
        <Box
          sx={{
            margin: '0 20px',
            borderRadius: '20px',
            background: '#F3F2F8',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            '& div': {
              display: 'flex',
              gap: '10px',
              '& p': {
                fontFamily: 'Avenir400',
                fontSize: '14px',
                color: '#020202',
                lineHeight: 'normal',
                span: {
                  fontFamily: 'Avenir600',
                },
              },
            },
          }}
        >
          <Box>
            <img src="svg/subs_checkmark.svg" alt="sign"></img>
            <Typography>
              Obtain an accurate <span>IQ score</span> through our scientifically validated test
            </Typography>
          </Box>
          <Box>
            <img src="svg/subs_checkmark.svg" alt="sign"></img>
            <Typography>
              See how your performance <span>compares</span> to the broader population
            </Typography>
          </Box>
          <Box>
            <img src="svg/subs_checkmark.svg" alt="sign"></img>
            <Typography>
              Discover your cognitive <span>strengths</span> and <span>areas for improvement</span>
            </Typography>
          </Box>
          <Box>
            <img src="svg/subs_checkmark.svg" alt="sign"></img>
            <Typography>
              Boost your IQ by <span>up to 37% in 4 weeks</span> with personalized training.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Header;
